import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";
import ImageWithCard from "../../components/ImageWithCard";
import WolfmanStatement from "../../components/WolfmanStatement";

function Thennow() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Then and Now" tagline="Scenes over the years" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
        <h1>Then & Now (1972 & 2006)</h1>
        <p>
         We thought you’d like to see what some of the Petaluma filming locations look like today compared to 1972 when American Graffiti was filmed. So, on behalf of Ron & Cynthia Simmons, John & Joetta Furrer, Wayne & Rhonda Van Patten, Jeff Sutton, Mike Carr, Don Phelps, Rose Vella, Steve Busse, Steve Rustad and Rich Poremba, we hope you enjoy the following images!
        </p>
        <div className="card-deck">
          <ImageWithCard image="../../images/film/thenNow/arcadeParking.gif" altText="Arriving At The Arcade" title="Arriving At The Arcade" description="The Pharaohs and Curt arrive at the arcade and miniature golf course.  Mary St. between Petaluma Blvd. North & Kentucky St.  Bank of America was and still is the building on the left. Across the street is Hill Plaza Park."/>
          <ImageWithCard image="../../images/film/thenNow/soap.gif" altText="Taking Revenge" title="Taking Revenge" description="Milner & Carol take revenge on the “water balloon throwing” girls in the Cadillac! Corner of Western Ave. & Petaluma Blvd. North At the time of fliming, the building next to the searchlight housed Wells Fargo Bank. Now, it is home to Vintage Bank."/>
          <ImageWithCard image="../../images/film/thenNow/couches.gif" altText="Curt Chases Dream Girl" title="Curt Chases Dream Girl" description="Curt runs up the street chasing his dream girl! Corner of Western Ave. & Kentucky St. The building in the background was Caruthers in 1972. Today it is Sleep City Mattress Center."/>
        </div>
        <div className="card-deck">
          <ImageWithCard image="../../images/film/thenNow/dragRace.gif" altText="Drag Race" title="Drag Race" description="The famous drag race on Paradise Road where Milner and Falfa square off to see who’s fastest!  Frates Road between South Ely Blvd. and Adobe Rd. Today, land on the south side of Frates Road remains undeveloped; however the land behind the location of the camera is now Adobe Creek Golf Course."/>
          <ImageWithCard image="../../images/film/thenNow/iceStorage.gif" altText="Debbie Waits For Toad" title="Debbie Waits For Toad" description="Debbie waits in car for Toad to “get us some brew”! 884 Bodega Ave. Formerly Gilardi’s Liquors, this is now Petaluma Sport & Dive Shop"/>
          <ImageWithCard image="../../images/film/thenNow/liquorStore.gif" altText="Outside Liquor Store" title="Outside Liquor Store" description="Toad outside of liquor store begging customers to buy him a pint of Old Harper! 884 Bodega Ave. Formerly Gilardi’s Liquors, this is now Petaluma Sport & Dive Shop"/>
        </div>
        <div className="card-deck">
          <ImageWithCard image="../../images/film/thenNow/paradiseRoad.gif" altText="Paradise Road" title="Paradise Road" description="The famous drag race on Paradise Road where Milner and Falfa square off to see who’s fastest! Frates Road between South Ely Blvd. and Adobe Rd. Today, land on the south side of Frates Road remains undeveloped; however the land behind the location of the camera is now Adobe Creek Golf Course."/>
          <ImageWithCard image="../../images/film/thenNow/phs.gif" altText="Outside Freshman Hop" title="Outside Freshman Hop" description="Outside the Freshman Hop. Is that the white T-bird Curt is looking for? 300 block of Broadway St. This is the parking lot adjacent to the Petaluma High School gym"/>
          <ImageWithCard image="../../images/film/thenNow/toadSick.gif" altText="Debbie Waits For Toad" title="Debbie Waits For Toad" description="Just before Toad finds Steve’s stolen Impala and gets roughed up by the bullies who sole it, Debbie sits on a car waiting for Toad to finish puking! Water Street, between Washington St. and new parkway; immediately adjacent to the Petaluma River. We’re not positive the “now” photo is the exact location where this scene was filmed as so much has changed since 1972. Presently this is our best estimate."/>
        </div>
        <div className="card-deck">
          <ImageWithCard image="../../images/film/thenNow/usedCarLot.gif" altText="Overzealous Salesman" title="Overzealous Salesman" description="The overzealous car salesman in the big rocking chair! 320 Petaluma Boulevard North This was and is still a used car lot; Auto Mario Used Car Sales"/>
          <ImageWithCard image="../../images/film/thenNow/watchingTV.gif" altText="Watching Ozzie & Harriet" title="Watching Ozzie & Harriet" description="Curt sitting on hood of car watching Ozzie & Harriet on TVs in store window! 145 Kentucky St. In 1972, this was Nielsen’s Appliance Store. Today it is Maguire's Pub."/>
          <ImageWithCard image="../../images/film/thenNow/xerb.gif" altText="XERB Radio Station" title="XERB Radio Station" description="Curt arrives at radio station XERB from where Wolfman Jack is broadcasting! Rovina Lane, just off Petaluma Blvd. South. This is the original location of Petaluma radio station KTOB 1490AM. The radio tower (immediately to the right, but not visible in these scenes) is still there and active; however it is scheduled to be relocated in the near future."/>
        </div>

      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Thennow;