import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Mercury() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '51 Pharaohs' Mercury" tagline="Cars of American Graffiti" backgroundImage="../../images/film/mercBanner.png"/>
      <div className="container">
        <h1>The '51 Pharaohs' Mercury</h1>
        <p>
          Thanks to Steve Rowan of Ontario, Canada and Rick Blevins of Overland Park, Kansas, we have been able to piece together information about what happened to the Merc after filming was completed in 1972.
          <br/>
          <br/>
          The car was returned to Universal Studios where it sat on a back lot until it was purchased by Dave Lee Roth of Van Halen fame who eventually sold it to rockabilly band Stray Cats leader Brian Setzer. Setzer is quoted as saying, “I had to sell that car for a really embarrassing price; it was a pile of junk.” If he only knew!
          <br/>
          <br/>
          From here, the story gets a bit hazy, but from what we know it was purchased by a young man in New York state. He may have got it directly from Setzer or there may have been one or more other owners in between. It is reported that the New York owner, after being dumped by his girlfriend, committed suicide while in the car! The owner’s father claims that is untrue, but apparently the blood stains are there. The father refuses to sell the car, opting to let it rot away in his storage/junk yard.
          <br/>
          <br/>
          <div class="row">
            <div class="col-sm-4 align-items-stretch d-flex">
              <img class="card-img-top" src="../../images/film/51merc1.jpeg" alt="owned by Setzer"/>
            </div>
            <div class="col-sm-4 align-items-stretch d-flex">
              <img class="card-img-top" src="../../images/film/51merc2.jpeg" alt="51 Mercury wasting away in New York"/>
            </div>
            <div class="col-sm-4 align-items-stretch d-flex">
              <img class="card-img-top" src="../../images/film/51merc3.jpeg" alt="51 Mercury wasting awa in a junk yard"/>   
            </div>
          </div> 
          The first photo is one taken during Setzer’s ownership. The othertwo are from the lot in New York. 
          <br/>
          What a shame to see such an icon waste away! 
        </p>
      </div>
    </React.Fragment>
  );
}

export default Mercury;