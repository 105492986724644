import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";
import WolfmanStatement from "../../components/WolfmanStatement";

function Wolfman() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The Wolfman" tagline="Robert Weston Smith" backgroundImage="../../images/film/wolfmanBanner.png"/>
      <div className="container">
      <h1>Wolfman Jack</h1>
        <p>
         Born January 21, 1938 in Brooklyn, New York, Robert Weston Smith went on to become the legendary Wolfman Jack; radio's most recognized and celebrated Disc Jockey. Regretfully, his life ended suddenly in 1995, the result of a heart attack, which he suffered in his hometown of Belvidere, North Carolina on July 1st.
         <br/>
         <br/>
         There is so much excellent material already written about The Wolfman that rather than attempt to reproduce it here, please use the links below to read all about this notorious character whose howl we all grew to love and cherish as it penetrated the airwaves no matter where we were when we cruised the main drag in our hometowns back in the'50s and '60s.
         <br/>
         <br/>
         His popularity is especially well characterized in American Graffiti as Pharaoh Carlos exclaims "He flies that thing all around the world". True to these words and with permission from the publisher, included below is an article that appeared in New Zealand's Petrolhead magazine in 2005.
        </p>
        <hr/>
        <p>
          American Graffiti was Wolfman Jack’s first movie appearance and in 1991 he wrote an article for Street Rodder Magazine about the film, which is summarized below.
          <br/>
          <br/>
          They built the Ford coupe mainly so that it would look good in front of the cameras. That meant there were parts of it that didn’t have to be put together perfectly. Well, there was nothing in the script that called for any shots of the undercarriage of a yellow coupe, so there was nothing special done to it. Same went for the interior.
          <br/>
          <br/>
          I remember really surprising the technical advisor of the film when I first saw the coupe. “Be careful, Wolfman,” he said. “I put a lotta work into that rod.”  “Yeah,” I told him, “I noticed the paint job is hand-rubbed.”  He looked shocked that I could tell. “That paint job cost $300,” he said. “We stripped six or seven coats of paint before the new job.”
          <br/>
          <br/>
          I wish I could have seen Harrison Ford’s ‘55 Chevy. At first I got all confused about the car. Since none of us knew Harrison Ford back then, I mean, he was as new to audiences as to me or to anyone else in the film. I kept wondering if the car was Harrison Ford’s ‘55 Chevy or Harrison Chevy’s ‘55 Ford.
          <br/>
          <br/>
          Anyway, Harrison played, you’ll remember, a guy called Big Bob Falfa and he drove a ‘55 Chevy that crashed in the film.  Well, that ‘55 Chevy had a stunt-double.  So did Harrison Ford, else you may never have seen him as Indiana Jones in his later films.
          <br/>
          <br/>
          “And baby, keep your tail-lights blinkin’, and rev your engine every chance you get, ‘cause da Wolfman is always here to fill your tank and make your hood feel goooooooood.   Awwwww-wowowowooooooooooooooooooooo!
        </p>
      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Wolfman;