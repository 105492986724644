import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Chevy() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '55 Chevy" tagline="Cars of American Graffiti" backgroundImage=""/>
      <div className="container">
        <h1>The '55 Chevy</h1>
        <p>
          There were three black ‘55 Chevys used in the film, two of which were also used in the 1971 movie Two Lane Blacktop.  The one used for exterior shots for both movies and driven by Harrison Ford in American Graffiti had a fiberglass shell that covered a 427 motor coupled to an M-22 Muncie transmission and 4.88 Olds rear-end. “We had so much trouble getting that damn Chevy started each time”, said Henry. “Initially, I thought it might be a problem with the starter motor so one time I told one of the owners of another ’55 to drive two wheels up on the curb. With a couple of wrenches, I crawled under it and removed the starter motor and put it in the movie car. As it turned out, the guy who built the car had put the battery in the trunk and apparently because so much of the car was fiberglass, somehow it wasn’t getting full power to the starter. So I went out and bought heavy duty battery cable and ran it directly to the starter motor.”
          <br/>
          <br/>
          The stunt car for both movies, which was used in American Graffiti’s drag race scenes, had a 454 motor, TH 400 automatic and unknown Olds rear-end. The third car, used for the explosion after the drag race rollover, was a non-running salvage yard ’55 Bel Air 2-door hardtop with a wood post installed in the "B" post area by Henry.
          <br/>
          <br/>
          Henry drove both the deuce and the ’55 Chevy in many scenes. Having grayish colored hair, he recalls having the make-up department spray his hair black each time he drove in a scene. “I raced that ’55 down Petaluma Boulevard in the first drag race scene where Falfa and Milner go off at the traffic light”, recounts Henry. “I also drove the coupe in the final drag race on Paradise Road.” The ‘55 wouldn’t roll over as part of the grand finale crash and had to be physically rolled on its side by the film crew. 
          <br/>
          <br/>
          After the movie, George Lucas gave both Chevys to Henry Travers as a thank you. (Henry was also in charge of selling the Deuce and Impala.) The ’55 stunt double ended up in a wrecking yard and was crushed. Despite the fact that his father warned him to keep all of the cars, Henry parted out the stunt car and eventually sold the remaining ’55 to Sam Crawford who later sold it to Steve Fitch of Wichita, Kansas. Fitch ended up selling the Chevy to its current owner, Wayne Newsome in Maryland where it is today.
        </p>

      </div>
    </React.Fragment>
  );
}

export default Chevy;