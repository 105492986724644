import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";


function ContrastCard (props) {
  return (
    <Contrast className=" card align-items-stretch d-flex">
      <ContrastImg className="card-img-top" src={props.image} alt={props.altText}/>
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">{props.text}</p>
        <Link target={props.target} to={props.url} className="btn btn-block btn-outline-primary">{props.button}</Link>
      </div>
    </Contrast>
  );
}

const Contrast = styled.div`
  background-color: #1A2930;
  color: #fff;
  padding: 2%;
`

const ContrastImg = styled.img`
  width: 100%;
`

export default ContrastCard;