import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Maley() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Bill Maley" backgroundImage="../../images/memorials/memorialBanner.png"/>
      <div className="container">
        <h1>Bill Maley</h1>
        <h3>March 5, 1930 to February 14, 2013</h3>    
        <p>
          We met Bill in 2007. Bill was the "Graffer" for George Lucas for both THX 1138 and American Graffiti. A gaffer is the "Chief Lighting Electrician" Bill's career as a gaffer spanned over 50 years. During his career, Bill was honored and awarded the "Northern California Film Pioneer Award" for opening the first stage (Stage A) in San Francisco. From the first day we met Bill, we became friends. The stories he told about the filming adventures during American Graffiti amazed us, and at times, made us all laugh. He even told us that if you watch closely, you will see him in the movie. Bill attend our Thursday night dinners for the last 6 years, entertaining all the guests at his table. Bill was also a big help in our recreation of the cop car rear end scene we did in 2008. Making sure we had the same lighting that he sent up for Mr. Lucas in 1972.
          <br/>
          <br/>
          "Good Bye Good Friend, Let There Be Light!""
        </p>
      </div>
    </React.Fragment>
  );
}

export default Maley;