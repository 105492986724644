import React from "react";
import styled from "styled-components";


function HeadCard (props) {
  return (
    <Card className="card col-xs-5 col-sm-4 col-md-3">
      <HeadShot className="card-img-top" src={props.image} alt={props.altText}/>
      <div className="card-body">
        <h5 className="card-title"><span style={{fontWeight:700}}>{props.office}</span><br/>{props.name}</h5>
        <p className="card-text">{props.profession}</p>
      </div>
    </Card>    
  );
}

const Card = styled.div`
  background-color: transparent;
  color: #1A2930;
  margin-bottom: 2vh;
  border: none;
  text-align: center;
`

const HeadShot = styled.img`
  border-radius: 50%;
  width: 100%;
`

export default HeadCard;