import React from "react";
import PageBanner from "../../components/PageBanner";
import ContrastSection from "../../components/ContrastSection";

import useScrollUp from "../../utilities/useScrollUp";
import MailToLink from "../../components/MailToLink";

function Volunteer() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Volunteer" tagline="Join the Crew" backgroundImage="../images/projects/volunteer/volunteerBanner.png"/>
      <div className="container">
        <h1>Volunteer</h1>
        <p>
          We fund each Salute celebration and raise money for our community through business sponsorships, donations, merchandise sales and vendor and event entry fees. The majority of the net proceeds from Cruisin’ The Boulevard’s annual American Graffiti Salute are used to fund our own projects and programs that benefit Petaluma Area residents.
        </p>
        <ContrastSection 
          heading="How to join the crew" 
          leftText="As a volunteer, you’ll have fun and make new friends. To get the ball rolling, please answer the following questions, so that we may add you to our database and contact you regarding your participation. Cruisin’ The Boulevard, Inc. holds this information in strictest confidence and will not share or sell any of it with any other individual, business or organization."
          link="mailto:volunteer@americangraffiti.net"
          cta ="Join Us"
          ContrastImg="../images/projects/volunteer/volSquare1.jpg"

          />
          <section>
            <h2>We need help with</h2>
            <hr/>
            <div className="row">
              <div className="col-sm-4 align-items-stretch d-flex">
               <div className="card">
                 <img className="card-img-top" src="../images/projects/volunteer/registrationSquare.jpg" alt="registration"/>
                 <div className="card-body">
                   <h5 className="card-title">Registration Crew</h5>
                   <p className="card-text">Check in show participants at the registration booth.</p>
                 </div>
               </div>
              </div>
              <div className="col-sm-4 align-items-stretch d-flex">
               <div className="card">
                 <img className="card-img-top" src="../images/projects/volunteer/parkingSquare.jpg" alt="parking"/>
                 <div className="card-body">
                   <h5 className="card-title">Cruise Crew</h5>
                   <p className="card-text">Help guide the cars into their show positions and along the cruise routes</p>
                 </div>
               </div>
              </div>
              <div className="col-sm-4 align-items-stretch d-flex">
               <div className="card">
                 <img className="card-img-top" src="../images/projects/volunteer/boothSquare.jpg" alt="Concessions Booth"/>
                 <div className="card-body">
                   <h5 className="card-title">Logistics Crew</h5>
                   <p className="card-text">Assist behind the scene keeping the wheels in motion</p>
                 </div>
               </div>
              </div>
            </div>
            <p>Do you have skills not listed above and want to help Cruisin' The Blulevard?  We would love to hear from you!  Please send us an email at: <MailToLink label="volunteer@americangraffiti.net" mailto="mailto:volunteer@americangraffiti.net" />, and let us know what you would like to do.</p>
          </section>
      </div>
    </React.Fragment>
  );
}

export default Volunteer;