import React from "react";
import { Link } from "react-router-dom";

function MovieCard (props) {
  return (
    <div className="col-sm-3">
      <Link to={props.link}>
        <div className="card">
          <img className="card-img-top" src={props.image} alt={props.altText}/>
          <div className="card-body">
            <h5 className="card-title">{props.title}</h5>
            <p className="card-text"></p>
          </div>
        </div>
      </Link>
    </div>
  );
}


export default MovieCard;