import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Travers() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Henry Richard Travers" backgroundImage="../../images/memorials/memorialBanner.png"/>
      <div className="container">
        <h1>Henry Richard Travers</h1>
        <h3>August 8, 1937 to August 20, 2006</h3>    
        <p>
          Although we only knew Henry for a short time, he had become a good friend. A gearhead in the truest sense of the word, he was a very kind and generous man and was looking forward to helping us duplicate the destruction of the police car scene at Salute 2008, in celebration of American Graffiti’s 35th anniversary. Of all of the films on which he worked, American Graffiti was his favorite and setting up the cop car was the feat of which he was the most proud. So Henry, in your honor, in 2008 ….stand by for justice!
          <br/>
          <br/>
          Born in New Bedford, Massachusetts, Henry was just a toddler when his parents moved to Castro Valley, California because the weather ideal and his father was able to find work. He attended Hayward High School, but it was at Castro Valley Elementary School when he was only seven that he met a little girl named Jackie! Who would have guessed that just 8 years later on September 14, 1952 that the two of them would marry and go on to share 54 years together as friends, lovers, partners, parents, grandparents and great grandparents.
          <br/>
          <br/>
          Henry at Santa Rosa radio station KSRO, 2006. Ironically he was also interviewed by KSRO back in 1972 when American Graffiti was filmed.One day in 1964 he was driving a fruit delivery truck through the Sonoma Valley. He liked it so much that he and Jackie moved to Sonoma where they lived for 42 years before he retired from the movie business and they relocated to Sparks, Nevada in 2001. Henry loved working with cars and trucks and even raced NASCAR between 1968 and 1972 (He was the 5th in California). It was during this time, in 1970, that he was asked to be a driver in the movie The Candidate and it was at that film’s wrap-up party where he first met George Lucas.
          <br/>
          <br/>
          Mr. Lucas asked him to be the transportation manager for a “car movie” that he was going to produce, an offer that he couldn’t refuse and so, American Graffiti launched Henry’s career in the film industry. He worked on many, many movies including More American Graffiti, The Conversation, Smile, Shoot the Moon and Jack, his last film before retiring. He also helped produce numerous commercials, many for Joe Pytka, one of the most prolific and acclaimed television commercial directors in the business. He was especially fond of a commercial that he worked on with John Wayne at Friday Harbor in the San Juan Islands of Washington State.
          <br/>
          <br/>
          Despite being away from home frequently, Henry was a family man and although daughter Sandra was their only child, he and Jackie enjoyed camping with Grandchildren Christi and John. Throughout his life, Papa Stinky Feet as he was affectionately named by Great Granddaughter Jessika, owned many cars including most recently a ’56 Ford Pickup and a ’57 Chevy 210 Station Wagon. He owned the Ford twice! After a heart attack at the age of 43, he sold it to family friend Don Orlandi and told him, “Remember, it will always be MY truck”. As a gift for his 61st birthday, Jackie bought it back for him!
          <br/>
          <br/>
          But alas, Henry has embarked on that ultimate cruise down the great boulevard in the sky. We miss him dearly, but we know he’s up there keeping an eye on us. In your honor, we have also established the Henry Travers’ Memorial Scholarship Fund. Oh, and by the way, watch out for the cop car parked at Jerry’s Cherries!
          <br/>
          <br/>
          See ya, buddy!
          <br/>
          Your friends at Cruisin’ The Boulevard!
        </p>
      </div>
    </React.Fragment>
  );
}

export default Travers;