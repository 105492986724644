import React from "react";
import PageBanner from "../../components/PageBanner";
import useScrollUp from "../../utilities/useScrollUp";


function Social  () {

  useScrollUp()
  return (
  <React.Fragment>
    <PageBanner heading="Kickoff Social" tagline="A Social Event" backgroundImage="../../images/show/socialBanner.jpg"/>
    <div className="container">
      <h1>Free Social Event</h1>
          <div className="row">
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/socialSquare1.png" alt="social event tents"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/socialSquare2.png" alt="social event parking the cars"/>
            </div>
            <div className="col-sm-4 align-items-stretch d-flex">
              <img className="card" src="../images/show/socialSquare3.png" alt="big chair movie prop"/>   
            </div>
          </div> 
          <hr/>
          <h2>What you can expect</h2>
          <hr/>
          <p>
            Get warmed up for the car show at this free social event at Plaza North Shopping Center, North McDowell Blvd. at Washington St in Petaluma.  No registration required, show up with your ride or just come to look at the other vehicles and hang out with others.  
            <br/>
            <br/>
            The Kickoff Social is also a chance to grab an event T-Shirt or other swag, from our partner Insignia who will have a both set up.  There is a silent auction and a chance to meet our schollarship winners.    
            <br/>
            <br/>
            Event is from 4pm- 8pm
          </p>
          <h2>Want to bring your car?</h2>
          <hr/>
          <p>
            While the event is free, parking in the designated area is reserved for 1972 and older American made vehicles, and will be given out on a first come first served baises.  Parking around the designated area is open to anyone, and any vehicle type.  You do not need to be attending the car show to attend the Kickoff Social however we ask that the same event etiquette be followed. 
          </p>
          <hr/>
          <h2>2023 Event Snap Shots</h2>
          <p>Thinking about attending in 2025? Here are a few shots from a recent Kickoff Social Event</p>
          <div className="row">
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Social1.png" alt="Insignia booth"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Social2.png" alt="raffel winner"/>
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Social3.png" alt="raffel bidding"/>   
            </div>
            <div className="col-sm-3 align-items-stretch d-flex">
              <img className="card" src="../images/show/23Social4.png" alt="3 foot salami prize"/>   
            </div>
          </div> 
          <br/>
      </div>
    </React.Fragment>
  );
}


export default Social ;