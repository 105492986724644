import React from "react";
import styled from 'styled-components';

function Jumbotron(props) {
  return (
    <React.Fragment>
      <div className="d-block d-sm-none">
        <MobleTron>
          <img src="../images/welcome/mobileSplash.png" alt="Parked cars of past show"/>
        </MobleTron>
        <div className="container">
          <h1>Welcome to Cruisin' The Boulevard</h1>
          <p>Home of the Salute to American Graffiti Car Show</p>
        </div>
      </div>
      <div className="d-none d-sm-none d-md-block">
        <JumbotronContainer className="jumbotron jumbotron-fluid">
          <JumbotronVideo poster="../images/welcome/splashBanner.jpg" preLoad={true} muted={true} autoPlay={true} loop={true} alt="Splash Video">    
              <source src="../images/welcome/banner.mp4" type="video/mp4"/>
          </JumbotronVideo>
          <div className="container">
            <JumbotronText className="display-4">Cruisin' The Boulevard</JumbotronText>
          </div>
        </JumbotronContainer>
      </div>
    </React.Fragment>
  );
}

const MobleTron = styled.div`
  height: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  color:#FFF;
`

const JumbotronContainer = styled.div`
  height: 60vh;
  position: relative;
  overflow: hidden;
  background-color:black;
  margin-bottom: 0;
`

const JumbotronVideo = styled.video `
  position: absolute;
  z-index: 1;
  top: 0;
  width:100%;
  height:100%;
  /*  object-fit is not supported on IE  */
  object-fit: cover;
  opacity:0.5;
`
const JumbotronText = styled.h1 `
  z-index: 2;
  position: relative;
  color: #c3451f;
  text-shadow: 2px 2px 5px #0A1612;
  font-weight: 900;
  font-size:8vw;

  @media (max-width: 575.98px) {
    display:none;
  }
`

export default Jumbotron;