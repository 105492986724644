import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Phelps() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Don Phelps" backgroundImage="../../images/memorials/memorialBanner.png"/>
      <div className="container">
        <h1>Don Phelps</h1>
        <h3>to October 2017</h3>    
        <p>
          Don Phelps was one of the original Cruisin' the Boulevard, Inc board members. His self proclaimed job as "entertainment director" helped bring acts to perform at our events.  Although Don retired as a director he maintained input in our event, joining the parking crew while his health permitted.  Don was also a Chrysler guru, specializing in Plymouths.  His favorite was his 1951 Plymouth.  Don also owned a 1950 and a hemi powered 1957 Plymouth.
        </p>
      </div>
    </React.Fragment>
  );
}

export default Phelps;