import React from "react";
import PageBanner from "../components/PageBanner";
import MovieCard from "../components/MovieCard";

import useScrollUp from "../utilities/useScrollUp";
import WolfmanStatement from "../components/WolfmanStatement";


function Movie() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="American Graffiti" tagline="The movie" backgroundImage="images/film/dragBanner.png"/>
      <div className="container"> 
      <h1>American Graffiti- the movie</h1>
      <p>
        Please enjoy the information on the following links about the movie, American Graffiti!  Chock full of facts and trivia, they are a fun cruise through the making of the movie and the people, the props and the locations that made this iconic movie come to life!
        <br/>
        For many of us, remembering 1962 brings back images of high school, girl or boyfriends, cars, Friday nights, and what we were going to do after high school. For me, I remember once my brother-in-law taking me cruising along Highway 1, the Great Highway. We feel that American Graffiti was Mr. Lucas’ attempt to let all those who were not around to know what it was like to be a teenager in the 1950’s and early 1960’s.  We think he did a great job!
        <br/>
        <br/>
        It's a common misconception that American Graffiti was filmed in Modesto, California, where Mr. Lucas grew up. The fact is that most of the memorable moments in George Lucas' timeless 'coming of age' movie classic were filmed in Petaluma, California in 1972. Actress Candy Clark recalls, "It was summer, but it was really cold"...very typical of summer nights in this Northern California city that today remains the quintessential American "hometown" with its historic iron-front and brick buildings, riverfront, and classic Victorian homes.
        <br/>
        <br/>
        Nearly all of the action in the film, which has several plot lines interwoven around Mr. Lucas' own teenage years in 1950's Modesto, takes place at night as teenagers in a typical American town of the post war era cruise the main drag (street) in their cars and hot rods. The intrinsic uniqueness of this film is that what is portrayed as happening in Modesto in 1962 was not unique at all! Whether you were a teenager in Bountiful, UT; Pontiac, MI; Somerset, KY; Hammonton, NJ; Petaluma, CA...or Anytown, U.S.A., you cruised! Back then, at least in California, we called it tooling'.
        <br/>
        <br/>
        George Lucas chose Petaluma as the setting for most of the filming when the city of San Rafael revoked his permit after the first night of shooting because a local bar owner complained that the late night schedule was disrupting his business. Mr. Lucas immediately jumped in his car and drove to film-friendly Petaluma, where he was welcomed with open arms. Although filming in San Rafael was allowed to continue for a couple more days, after making necessary logistical arrangements, director Lucas moved his production to Petaluma and completed filming most of the movie here.
        <br/>
        <br/>
        American Graffiti premiered on August 1st, 1973 at the Petaluma theater now named McNear's Mystic Theatre and Music Hall, and at other locations. It won instant critical acclaim and quickly became a box office smash! Regarded as one of Mr. Lucas' best and most personal films, it earned four Academy Award nominations, including Best Picture. Filming took just 28 nights and came in on budget at what is purported to have been $777,777.77. Between worldwide box office receipts and video rentals, it is reported to have grossed more than $170 million (perhaps the most profitable movie ever filmed) and provided Lucas with the financial resources to produce his Star Wars and Indiana Jones serials and to establish his Bay Area special effects studio, Industrial Light and Magic, along with Lucasfilm Ltd. and LucasArts.
        <br/>
        <br/>
        The film's young cast included many actors and actresses that today are either Hollywood stars or American Graffiti culture icons: Ron Howard, Richard Dreyfuss, Harrison Ford, Cindy Williams, Mackenzie Phillips, Kathleen Quinlan, Suzanne Sommers, Paul Le Mat, Candy Clark, and Bo Hopkins.
        <br/>
        <br/>
        In 2002, Clark, who earned a Best Supporting Actress Academy Award nomination for her role in the film, and Hopkins, returned to Petaluma for a re-creation of the "drag" race episode and to visit other filming locations for a special "Travel Channel" production (photo above).
        <br/>
        <br/>
        Contributors to this site include George Lucas, Henry Travers, Cynthia Simmons, Terry Park, John Furrer, Eddie McCullough, Skip White, Michael Baddeley, Toni Moheng, Paul Haun, Jim Bergstrom, and Rich Poremba. Photography contributors include Steve Rustad, Ron & Cynthia Simmons, Dana Gustafson, John Maher and Rich Poremba.
      </p>
      <section>
        <div className="row">
          <MovieCard image="../../images/film/thenNowSquare.png" altText="Then and Now" title="Then and Now" link="movie/thennow"/>
          <MovieCard image="../../images/film/essentialSquare.jpg" altText="The Essentials" title="The Essentials" link="movie/essentials"/>
          <MovieCard image="../../images/film/musicSquare.jpg" altText="The Music" title="The Music" link="movie/music"/>
          <MovieCard image="../../images/film/carSquare.jpeg" altText="The Cars" title="The Cars" link="movie/cars"/>
        </div>
        <div className="row">
          <MovieCard image="../../images/film/film_locations.png" altText="Filming Locations" title="Filming Locations" link="movie/filming"/>
          <MovieCard image="../../images/film/wolfmanJack.jpeg" altText="Wolfman Jack" title="Wolfman Jack" link="movie/wolfman"/>
          <MovieCard image="../../images/film/triviaSquare.jpg" altText="Trivia and Technicalities" title="Trivia and Technicalities" link="movie/trivia"/>
          <MovieCard image="../../images/film/sceneSquare.jpg" altText="Behind the Scenes" title="Behind the Scenes" link="movie/scenes"/>
        </div>
      </section>
      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Movie;