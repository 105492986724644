import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Padilla() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Manuel Padilla, Jr." backgroundImage="../../images/memorials/padillaBanner.png"/>
      <div className="container">
        <h1>Manuel Padilla, Jr.</h1>
        <h3>July 13, 1955 to January 29, 2008</h3> 
        <p>
        Manuel made a name for himself long before his appearance in American Graffiti. For years he was a child actor playing the Mexican or Indian boy in TV westerns like Rawhide, Bonanza, and Gunsmoke. He played in two different Tarzan movies and was chosen to play as a regular cast member on Tarzan the TV show starring Ron Ely. After Tarzan, Manuel became a regular on The Flying Nun, as Marcello, the little boy who befriended Sister Bertrille, (Sally Field). He appeared in over 30 films and TV shows including Dime With a Halo, The Great White Hope, A Man Called Horse, Police Story and Happy Days. Padilla even reprised his role as American Graffiti's Carlos in the sequel, More American Graffiti. His final film was Brian De Palma's Scarface. Manuel passed away unexpectedly after making a public appearance at the Grand National Roadster Show in Pomona, California. He leaves behind his parents, four children, one brother and numerous other family members.
        <br/>
        <br/>
        A good friend of Cruisin’ The Boulevard, the only thing little about Manuel was his height! Gentle, sincere and warm, he had a great big heart of gold and when he was in your company, he had an aura about him that just made you feel like everything was right in the world! He was with us for both our 2006 and 2007 American Graffiti Salutes and we were so looking forward to having him back in 2008, but that just wasn’t to be. We’ll miss him dearly and our hearts go out to his family, friends and to all of those whose lives he touched.
        <br/>
        <br/>
        “You tell’em Wolfman! He’s my man. When I graduate, I’m gonna be a Wolfman.”
        <br/>
        <br/>
        “That’s just a clearing station man, so he can fool the cops. He flies that thing all the way around the world. It’s against the law, man.”
      </p>
      </div>
    </React.Fragment>
  );
}

export default Padilla;