import React from "react";
import PageBanner from "../../../components/PageBanner";
import useScrollUp from "../../../utilities/useScrollUp";

function Citroen() {
  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '67 Citroen 2CV" tagline="Cars of American Graffiti" backgroundImage="../../images/film/citroenBanner.png"/>
      <div className="container">
        <h1>The '67 Citroen 2CV</h1>
        <hr/>
        <h2>Citroen in the News</h2>
        <h4>Sonoma County's Quirky "Tin Snail" — 1967 Citroën 2CV of Cult Movie American Graffiti Fame</h4>
        <p>
          One classic car featured in the fleet of motors in iconic 1973 coming-of-age classic movie "American Graffiti" is considerably more quirky than cool.
          <br/>
          <br/>
           Known in its native France as "The Tin Snail", this sturdy little "Picnic Version" of the 1967 Citroën 2CV  (Deux Chevaux) might not be much to look at, but its collectability value puts it top of the pops with international cult-movie automobile enthusiasts.
         </p>
         <p className="right">Read full article at <a href="https://www.southernsonomacountrylife.com/blogs/2015/03/sonoma-countys-quirky-tin-snail-1967-citro%C3%ABn-2cv-of-cult-movie-american-graffiti-fame.html" target="_blank" rel="noreferrer">Souther Sonoma County Life</a></p>

      </div>
    </React.Fragment>
  );
}

export default Citroen;