import React from "react";
import { Link } from "react-router-dom";
import PageBanner from "../components/PageBanner";
import HeadCard from "../components/HeadCard";

import useScrollUp from "../utilities/useScrollUp";


function About() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="" tagline="" backgroundImage="images/about/23aboutBanner.png"/>
      <div className="container">
        <h1>About Cruisin' the Boulevard</h1>
        <p>
          Cruisin' the Boulevard, Inc., an all-volunteer, tax-exempt 501(c)(3) nonprofit California Public Benefit Corporation, was founded in 2005 by a group of classic car and American Graffiti enthusiasts to pay tribute to the film, rekindle the "happy days" it portrays, and benefit Petaluma. Net proceeds from each annual Salute are invested in our own community projects as well as other community programs that benefit arts, education, history, safety and welfare.
        </p>
        <section className="board">
          <h2>Meet the Board</h2>
          <h4>Board of Directors (Officers)</h4>
          <div className="card-deck">
            <HeadCard image="/images/about/azevedoBHead.jpeg" altText="Brian Azevedo" office="President: " name="Brian Azevedo" profession="A&A Automotive"/>
            <HeadCard image="images/about/gustafsonHead.png" altText="Greg Gustafson" office="Vice President: " name="Greg Gustafson" profession="Gulick's Auto Body"/>
            <HeadCard image="images/about/glennHead.png" altText="Lori Glenn" office="Treasurer: " name="Lori Glenn" profession="Pharmacy Technician"/>
            <HeadCard image="/images/about/eadsHead.jpg" altText="Breana Eads" office="Secretary: "name="Breana Eads" profession="Technical Support Engineer"/>
          </div>
          <br/>
          <h4>Board Members</h4>
          <div className="card-deck">
            <HeadCard image="images/about/svinthHead.png" altText="Ron Svinth" name="Ron Svinth" profession="Service Manager, Heavy Equipment Rental Company"/>
            <HeadCard image="images/about/azevedoHead.jpg" altText="Art Azevedo" name="Art Azevedo" profession="A&A Automotive"/>
            <HeadCard image="/images/about/mancillHead.png" altText="Keith Mancill" name="Keith Mancill" profession="Retired UPS Supervisor"/>
            <div className="col-sm-3"/>
          </div>
        </section>
        <hr/>
        <div>
          <h4>Emeritus Board Memebers</h4>
          <div className="row">
            <div className="col-sm-4">
              <h5 className="card-title">Richard Poremba</h5>
            </div>
            <div className="col-sm-4">
              <h5 className="card-title">Rhonda Van Patten</h5>
            </div>
            <div className="col-sm-4">
              <h5 className="card-title">Wayne Van Patten Jr.</h5>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-sm-4">
              <h5 className="card-title">John Furrer</h5>
            </div>
            <div className="col-sm-4">
              <h5 className="card-title">Joetta Furrer</h5>
            </div>
            <div className="col-sm-4">
            </div>
          </div>
        </div>
        <hr/>
        <section>
          <h3>Current projects</h3>
          <h4><Link to="projects/life">Petaluma Save-A-Life Project</Link></h4>
          <p>
            Through our Petaluma Save-A-Life project, we have donated over 100 Automated External Defibrillators (AEDs) into our community. From our police patrol cars to our high schools to many public buildings, we are making a difference.
            <br/>
            <br/>
            We have also purchased an electrically powered ambulance cot for the Petaluma Fire Department and supported the Petaluma Police Department’s Bicycle Helmet Safety Program.
            <br/>
            <span className="wrapRight"><Link to="projects/life">Learn More</Link></span>
          </p>
          <h4><Link to="projects/scholarships">Scholarships</Link></h4>
          <p>
            The Board of Directors of Cruisin’ the Boulevard, Inc., is proud to offer our support in the pursuit of vocational education in memory of our dear friends. 
            <br/>
            <span className="wrapRight"><Link to="projects/scholarships">Learn More</Link></span>
          </p>
        </section>
        <br/>
        <hr/>
{/*        <section className="contrast">
          <h2 className="center">Follow Us on Facebook</h2>
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="contrastText">
                <p className="align-middle">
                  Several times a week enjoy posts about American Graffitti the movie, classNameic cars, car shows, memes and other content that brings you back to '62.  Join the conversation and follow us on Facebook. 
                </p>
                <h5 className="right">
                  <Link to={{ pathname: "https://www.facebook.com/AmericalGraffitiPetaluma" }} target="_blank" rel="noreferrer">Follow Us</Link>
                </h5>
              </div>
            </div>
            <div className="col-sm-6">
              <a className="d-none d-lg-block" href="https://www.facebook.com/AmericalGraffitiPetaluma" target="_blank" rel="noreferrer">
                <iframe title="facebookPage" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAmericalGraffitiPetaluma&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="500" ></iframe>
              </a>
              <a className="d-none d-md-block d-lg-none" href="https://www.facebook.com/AmericalGraffitiPetaluma" target="_blank" rel="noreferrer">
                <iframe title="facebookPage" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAmericalGraffitiPetaluma&tabs=timeline&width=320&height=3100&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="310" height="310" ></iframe>
              </a>

            </div>   
          </div>
        </section>*/}
      </div>
    </React.Fragment>
  );
}

export default About;
