import React from "react";
import styled from "styled-components";


function ContrastSection (props) {
  return (
    <Contrast>
      <Heading>{props.heading}</Heading>
      <div className="row align-items-center">
        <div className="col-sm-6">
            <ContrastText>
                <p className="align-middle">
                {props.leftText}
                </p>
                <ContrastLink><a href={props.link} style={{color:"#fff"}}>{props.cta}</a></ContrastLink>
            </ContrastText>
        </div>
        <div className="col-sm-6"><ContrastImg className="mx-auto d-block" src={props.ContrastImg}/></div>        
      </div>
    </Contrast>
  );
}

const Contrast = styled.div`
  width: 100%;
  background-color: #1A2930;
  color: #fff;
  margin: 2% 0;
  padding: 2%;
  position: relative;
`

const Heading = styled.h2`
  text-align:center;
`

const ContrastText = styled.div`
  padding: 10%;
`

const ContrastLink = styled.p`
  color: #fff;
  text-align:right;
`

const ContrastImg = styled.img`
  width: 90%;
`

export default ContrastSection;