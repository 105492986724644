import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function FourBox (props) {
  return(
    <React.Fragment>
      <div className="d-block d-sm-none">
        <div className="col-12">
          <img src="images/welcome/showSquare.jpg" alt="Car Show Square"/>
          <p>
            Petaluma’s Salute to American Graffiti is an annual celebration of American Graffiti, classic American cars, cruising the “main drag”, rock ‘n roll music and the “happy days” of the ‘50s and early ‘60s. This multi-day community-wide celebration is most known for large car show and cruise.
          </p>
          <button className="btn btn-block btn-outline-primary" ><Link to="./show">Join the Fun</Link></button>
        </div>
        <br/>
        <div className="col-12">
        <img src="images/welcome/filmSquare.jpg" alt="Film Square"/>
          <p>
            Where were you in '62?  Although portions of American Graffiti were produced in other Northern California locations, most of the memorable scenes in George Lucas’ timeless, coming-of-age movie were filmed right here in Petaluma in June and July, 1972.
          </p>
          <button className="btn btn-block btn-outline-primary" ><Link to="./movie">Learn More</Link></button>
          <br/>
        </div>
          
      </div>
      <div className="d-none d-sm-none d-md-block">
        <div>
          <div className="row no-gutters align-items-center">
            <div className="col-sm-6">
              <SplashText>
                <p>
                  Petaluma’s Salute to American Graffiti is an annual celebration of American Graffiti, classic American cars, cruising the “main drag”, rock ‘n roll music and the “happy days” of the ‘50s and early ‘60s. This multi-day community-wide celebration is most known for large car show and cruise.
                </p>
                <h5 className="right"><Link to="./show">Join the Fun</Link></h5>
              </SplashText>
            </div>
            <div className="col-sm-6"><Graphic src="images/welcome/showSquare.jpg" alt="Car Show Square"/></div>            
          </div>
          <div className="row no-gutters align-items-center">
            <div className="col-sm-6"><Graphic src="images/welcome/filmSquare.jpg" alt="Film Square"/></div>
            <div className="col-sm-6">
              <SplashText>
                <p>
                  Where were you in '62?  Although portions of American Graffiti were produced in other Northern California locations, most of the memorable scenes in George Lucas’ timeless, coming-of-age movie were filmed right here in Petaluma in June and July, 1972.
                </p>
                <h5 className="right"><Link to="./movie">Learn More</Link></h5>
              </SplashText>
            </div>            
          </div>
        </div>
        

      </div>
    </React.Fragment>
    );
}

const SplashText =styled.div `
  color: #1A2930;
  padding: 10vh;
  width:100%
`
const Graphic = styled.img `
  width:100%
`

export default FourBox;