import React from "react";
import FourBox from "../components/FourBox";
import Jumbotron from "../components/JumboTron";
import FeaturedSponsors from "../components/FeaturedSponsors";
import ContrastCard from "../components/ContrastCard";

import useScrollUp from "../utilities/useScrollUp";

function Home() {

    useScrollUp()
      return (
        <React.Fragment>
            <Jumbotron/>
            <div className="container">
                <FourBox/>
                <section>
                    <h2>Community Focused</h2>
                    <hr/>
                    <div className="card-deck">
                        <ContrastCard image="../images/welcome/scholarship2019square.jpg" altText="Schalarship winners" title="Scholarships" text="Applications open to Petaluma's graduating high school seniors as well as college students." button="Learn More" url="projects/scholarships"/>
                        <ContrastCard image="../images/welcome/carshowSquare.jpg" altText="Car Show" title="Giving Back" text="Cruisin' The Boulevard, Inc. has several community projects that proceeds from our annual Salute Celebrations are helping to fund." button="Learn More" url="show"/>
                        <ContrastCard image="../images/welcome/24artSquare.png" altText="Event Art" title="Event Art" text="Cruisin' the Boulevard original and reproduction event artwork, designed by local artist Elise Durenberger" button="Learn More" url="art"/>
                    </div>     
                </section>

                <section>
                    <div className="row">
                        <div className="">
                            
                        </div>
                    </div>          
                </section>

                <FeaturedSponsors/>

            </div>
        </React.Fragment>

      );
}

export default Home;