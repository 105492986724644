import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom';

function Navigation(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light">
      <Navbar.Brand as={Link} to="/">
        <img
          src="/logo.png"
          height="30"
          className="d-inline-block align-top"
          alt="CTB logo"
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/show">Car Show</Nav.Link>
          <Nav.Link as={Link} to="/show/sponsors">Sponsors</Nav.Link>
          <Nav.Link as={Link} to="/projects/scholarships">Scholarships</Nav.Link>
          <Nav.Link as={Link} to="/about">About</Nav.Link>
          <NavDropdown title="Projects" id="collasible-nav-dropdown">
            <NavDropdown.Item as={Link} to="/projects/life">Save-a-Life</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/projects/memorial">In Memory</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/projects/volunteer">Volunteer</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/movie">American Graffiti</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;