import React from "react";
import PageBanner from "../../components/PageBanner";
import ContrastSection from "../../components/ContrastSection";

import useScrollUp from "../../utilities/useScrollUp";

function Life() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Save a Life" tagline="AEDs for the Community" backgroundImage="../images/projects/life/aedBanner.png"/>
      <div className="container"> 
      <h1>Save-A-Life</h1>
      <p>
        Through our Petaluma Save-A-Life project, we have donated over 100 Automated External Defibrillators (AEDs) into our community, which helped establish Petaluma as a HeartSafe community. From our police patrol cars to our high schools to many public buildings, we are making a difference.
        <br/>
        We have also purchased an electrically powered ambulance cot for the Petaluma Fire Department and supported the Petaluma Police Department’s Bicycle Helmet Safety Program.
      </p>
      <h2>AEDs Save Lives</h2>
      <p>
        When someone has a heart attack, what you do for them within the next few minutes can make the difference between life and death. An automated external defibrillator (AED) diagnoses and, if necessary, shocks the heart of a person experiencing cardiac arrest due to ventricular fibrillation ortachycardia. This treatment – called defibrillation – enables the heart to attempt to re-establish an effective rhythm.
        <br/>
      </p>
      <ContrastSection 
          heading="What is an AED" 
          leftText="An AED, or automated external defibrillator, is used to help those experiencing sudden cardiac arrest. It's a sophisticated, yet easy-to-use, medical device that can analyze the heart's rhythm and, if necessary, deliver an electrical shock, or defibrillation, to help the heart re-establish an effective rhythm."
          link="mailto:info@americangraffiti.net"
          cta ="Request one for your establishment"
          ContrastImg="../images/projects/life/aedSquare.jpg"

          />
      {/*<section>
        <h2>Where can you find an AED?</h2>
        <div className="row">
          <div className="col-sm-6">
            <ul>
              <li>Hermann Sons Hall</li>
              <li>Petaluma High School</li>
              <li>Casa Grande High School</li>
              <li>Petaluma City Police Depeartment</li>
            </ul>
          </div>
           <div className="col-sm-6">
            <ul>
              <li></li>
            </ul>
          </div>
        </div>
      </section>
      <div>
        <h2>In the news</h2>
        <hr/>
        <div className="row">
            <div className="col-12">
              <h4>Life saved with CPR AED</h4>
              <p>
                On the morning of Saturday August 26th 2017 at 9:47am Petaluma Fire Department Station 3 units were dispatched to the Rooster Run Golf Course for a cardiac arrest on the course.  Bystanders on the golf course started CPR, called 911, and notified the courses club house.  Personnel at the club house retrieved the AED that had recently been installed and rushed it to the 14th hole.  Bystanders and staff deployed the AED and delivered a total of three shocks to the downed man.  While en route, dispatch updated fire units about CPR being in progress and that an AED had been deployed.
                <br/>
                ...
                <br/>
                Public access AED’s have recently been placed in several Petaluma locations through generous donations and coordination by public, private, and community organizations.  Some include the Petaluma Fire Department, the Petaluma Healthcare District’s “Heartsafe Community” program, Cruisin’ the Boulevard’s “Save a Life” program, Save Lives Sonoma, and this specific AED at Rooster Run was donated by the Petaluma Valley Rotary only a year ago. 
              </p>
              <p className="right">Read full article at <Link to={{pathname: "https://local.nixle.com/alert/6128825/"}} target="_blank">Local Nixle</Link></p>
            </div>
            <hr/>
            <div className="col-12">
              <h4>Heart-saving tech now in all Petaluma schools</h4>
              <p>
                A years-long effort to install life-saving cardiac arrest treatment devices on the grounds of every Petaluma school wrapped up on Wednesday, a major milestone as various groups work to support the proliferation of automated external defibrillators, or AEDs, throughout the city.
                <br/>
                ...
                <br/>
                Organized efforts to place AEDs around Petaluma began as early as 2007, when the nonprofit Cruisin’ the Boulevard, the organization behind the annual classNameic car-infused celebration of the film American Graffiti, began funneling its revenue toward acquiring and donating the devices.
                <br/>
                <br/>
                The initiative helped kickstart the proliferation of AEDs throughout the city, resulting in donations to the city’s larger schools, City Hall, police patrol cars, some periphery fire department vehicles and elsewhere, Schach said.
              </p>
              <p className="right">Read full article at <Link to={{ pathname: "https://www.petaluma360.com/article/news/heart-saving-tech-now-in-all-petaluma-schools/"}} target="_blank">Argus Courier</Link></p>
            </div>
        </div>

      </div>*/}
      </div>
    </React.Fragment>
  );
}

export default Life;