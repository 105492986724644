import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";
import WolfmanStatement from "../../components/WolfmanStatement";

function Filming() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Filming Locaitons" tagline="iconic spots" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
      <h1>Filming Locations</h1>
          <p>
            Portions of American Graffiti were filmed in Berkeley, San Francisco, San Rafael, Mill Valley, Sonoma, Marin County, Concord and Pinole, however most of the memorable scenes were filmed here in Petaluma, June – August, 1972.
            <br/>
            <br/>
            Many American Graffiti fans who come to Petaluma want to know where the various scenes were filmed, so we’ve prepared a detailed filming locations map.
          </p>
          <section>
            
          </section>
          <section>
            <div className="col-12">
                <h5>Cruising Scenes: Petaluma Boulevard North</h5>
                <p>The location of many of the cruising scenes. Cruising filmed here is distinguishable from cruising filmed in San Rafael by two-way traffic. San Rafael scenes are all one-way traffic.</p>
            </div>

            <div className="col-12">
                <h5>Little Bitty Spacemen</h5>
                <p>321 Petaluma Boulevard South, Pinky's Pizza
                <br/>
                Although not seen in the movie, this is the pizza parlor that Wolfman Jack calls on the telephone and asks if they have the Secret Agent Spy Scopes that "pull in the moon, the stars, the planets and the satellites, and the little bitty spacemen". The pizza man mistakenly thinks Wolfman is asking for the "hit parade on a stethoscope".  Pinky’s Pizza is still in business today and they serve a great pizza.  We are sorry to say that they are all out of the Secret Agent Spy Scopes!</p>
            </div>
             
            <div className="col-12">
                <h5>Curt's Dream Girl</h5>
                <p>
                  172 Petaluma Boulevard North near Washington Street
                  <br/>
                  Curt's first encounter with his T-bird dream girl while sitting in the back seat of Laurie's Edsel as they drive to the hop. There the cars were headed north on Petaluma Blvd.
                </p>            </div>  

            <div className="col-12">
                <h5>Toad's Accident</h5>
                <p>
                  Washington Street at Petaluma Boulevard North.
                  <br/>
                  This is the intersection where Terry 'the Toad" Fields challenges another cruiser to a street race, forgets his car is in reverse gear when he stomps on the gas pedal, and goes backward, crashing into the car behind him.  The cars were headed east on Washington St.
                </p>            </div>  
                
            <div className="col-12">
                <h5>The Overzealous Car Salesman</h5>
                <p>320 Petaluma Boulevard North
                <br/>
                The location of the used car lot where Toad, driving Steve Bolander's car, stops to inspect the damage after his cruising accident and is confronted by the overzealous car salesman sitting in a giant rocking chair.</p>
            </div>

            <div className="col-12">
                <h5>The Freshman Hop</h5>
                <p>201 Fair Street, Petaluma High School
                <br/>
                Used for filming of the scenes outside of the high school freshman hop and inside the school where Curt Henderson tries to open his old locker as he strolls through the empty halls reminiscing his high school years on his last night before leaving for college. The hop itself was filmed in one day inside the gym at Mt. Tamalpais High School, Mill Valley, California.</p>
            </div>

            <div className="col-12">
                <h5>Chasing a Dream</h5>
                <p>The Corner of Western Avenue & Kentucky Street
                <br/>
                This is where Curt stood after running after the white T-Bird driven by his blonde "dream girl".</p>            </div>

            <div className="col-12">
                <h5>The Liquor Store</h5>
                <p>884 Bodega Ave, Petaluma Sport Shop and Dive Center
                <br/>
                In 1972, this was a liquor store and is the filming location of the episode where Toad tries to buy "hard liquor" for his new girlfriend, Debbie Dunham. He fails, but gets the booze anyway, when a man he asks to buy it for him robs the liquor store and tosses him the bottle as he runs out of the store with the gun-blasting owner close behind.</p>
            </div>

            <div className="col-12">
                <h5>Curt Meets The Pharaohs</h5>
                <p>145 Kentucky Street, Maguire's Pub
                <br/>
                An appliance store in 1972, this is where Curt sits atop a car's hood watching an episode of Ozzie & Harriet on a TV in the store's window before being confronted by Pharaoh's Car Club leader Joe Young and members Ants and Carlos.</p>
            </div>

            <div className="col-12">
                <h5>Carol's Revenge</h5>
                <p>The Corner of Western Avenue & Petaluma Boulevard North
                <br/>
                This is the location where Carol, after being hit by a water balloon, and Milner jump from Milner's yellow deuce coupe to take revenge on some girls by "soaping" the windows of their car with shaving cream and flattening its tires.</p>
            </div>

            <div className="col-12">
                <h5>Outside the Miniature Golf Course & Arcade</h5>
                <p>Mary Street between Bank of America & Hill Plaza Park.
                <br/>
                The white T-Bird driven by Curt's dream girl is seen turning left onto Mary Street from Petaluma Boulevard North (the wrong direction on the one-way street - watch for the arcol-12 painted on the pavement) as the Pharaoh's Mercury approaches from the opposite direction and parks in front of the arcade and miniature golf course. (See 'other locations' below).</p>
            </div>

            <div className="col-12">
                <h5>Police Car Destruction</h5>
                <p>23 Petaluma Blvd. North, McNear's Mystic Theatre & McNear's Saloon & Dining House
                <br/>
                The white T-Bird driven by Curt's dream girl is seen turning left onto Mary Street from Petaluma Boulevard North (the wrong direction on the one-way street - watch for the arcol-12 painted on the pavement) as the Pharaoh's Mercury approaches from the opposite direction and parks in front of the arcade and miniature golf course. (See 'other locations' below).</p>
            </div>

            <div className="col-12">
                <h5>Outside the Miniature Golf Course & Arcade</h5>
                <p>Mary Street between Bank of America & Hill Plaza Park.
                <br/>
                The marquee on the old State Movie Theater (now the Mystic Theater) visible in the background of the scene below, read Dementia 13, one the first films directed by Frances Ford Coppola, producer of American Graffiti and a close friend of Mr. Lucas. Director Lucas also honored his own first film THX 1138 by having THX 138 for the license number on the '32 deuce coupe driven by Milner. The parking lot next to McNear's Saloon & Dining House was transformed into Jerry's Cherries Used Car lot and is the location of perhaps the film's most memorable and funniest scene. Curt, as an initiation into the Pharaohs' Car Club, secretly cables the rear axel of a parked police car to a light pole. When the police attempt to peel out of the lot to chase the Pharaoh's Mercury, the rear axle is ripped from beneath the car!</p>
            </div>

            <div className="col-12">
                <h5>Laurie's New Boyfriend</h5>
                <p>43 Petaluma Blvd. North, in front of Tall Toad Music
                <br/>
                This is where Laurie, after her fight with Steve, parks her Edsel and gets into the black '55 Chevy driven by Bob Falfa, a rival from another town.</p>
            </div>

            <div className="col-12">
                <h5>Finding Steve's Stolen Car</h5>
                <p>Water Street, adjacent to the Petaluma River
                <br/>
                The location where Toad gets sick, but then finds Steve's stolen car. While trying to hotwire it, he is confronted by the two thieves (one of whom is played by Johnny Weissmuller, Jr.) who proceed to rough him up before Milner jumps from his coupe and beats them off. Debbie exclaims "You're just like the Lone Ranger".</p>
            </div>

            <div className="col-12">
                <h5>The Gas Station</h5>
                <p>California Highway 101 (Old Redwood Highway) about 2 miles south of Petaluma 
                <br/>
                The gas station where Milner works and goes to open the headers on his coupe in preparation for the drag race. Update:  The state is widening Highway 101 and the plans say that this station will be going away to make room for the road widening.</p>
            </div>

            <div className="col-12">
                <h5>South of town, XERB Radio Station</h5>
                <p>Rovina Lane just off Petaluma Boulevard South
                <br/>
                The remote location of Wolfman Jack's XERB radio station where Curt goes seeking to get The Wolfman to broadcast a message from him to his dream girl. Curt drives up a dark road, parks, gets out of his car, and gazes upward at the radio transmission tower. This is the original location of Petaluma's longtime radio station, KTOB 1490 AM. Scenes inside the studio were filmed in Berkeley at KRE 1400 AM…see below.</p>
            </div>

            <div className="col-12">
                <h5>The Drag Race</h5>
                <p>Frates Road between Ely Boulevard South and Adobe Road
                <br/>
                Adjacent to what is now Adobe Creek Golf Club is the film's infamous "Paradise Road," site of the grand finale drag race between Milner and Falfa.</p>
            </div>
          </section>

          <section>
          <h2>Other Locations</h2>
            <div className="col-12">
                <h5>Mel's Drive-In</h5>
                <p>San Francisco; South Van Ness Boulevard & Mission Street
                <br/>
                It’s a misnomer about Mel’s Drive-In Burger City being closed and re-opened just for filming and then demolished immediately thereafter. Dennis Kay, Director of Operations for Foster’s West, the owner of Mel’s at the time, informs us that he was responsible for making the arrangements for the filming. The restaurant was not permanently closed for business and in fact continued in business for several years after the filming was completed. Foster’s West eventually went out of business, but Dennis remembers it being sold to another owner who he believes kept it open for several more years. Dennis recalls, “We only closed for business on the nights of filming and re-opened the next day. Weeks after filming was completed, they called and said some film had been lost on the cutting room floor, so we had one more night of filming.” He also stated that the reason this drive-in was selected was because it was the only one of the era that could be found that had a tree high enough behind it to block out the modern buildings in the background.</p>
            </div>

            <div className="col-12">
                <h5>More Cruising Scenes</h5>
                <p>San Rafael; 4th Street
                <br/>
                The location of some of the general cruising scenes, which are easily identified when all traffic is seen moving in one direction. Petaluma Boulevard scenes have two-way traffic. 4th Street is the location where Milner picks up Judy's sister Carol and where he is stopped by police officer Holstein and receives a ticket for his burned out rear license plate light. In another scene, Toad spots bitchin' Debbie walking along the sidewalk, tells her that she looks like Connie Stevens and then picks her up.</p>
            </div>

            <div className="col-12">
                <h5>The Freshman Hop</h5>
                <p>Mill Valley; Mt. Tamalpais High School
                <br/>
                The school's gym was decorated for the freshman hop and is where all of the dancing and restroom scenes were filmed. The band, Herby and The Heartbeats (all the way from Stockton) is Flash Cadillac and the Continental Kids, a group that originated at the University of Colorado in 1969. Outside hop scenes were filmed at Petaluma High School.</p>
            </div>

            <div className="col-12">
                <h5>The Auto Wrecking Yard</h5>
                <p>Sonoma; East 8th Street
                <br/>
                Rusty's Auto Wrecking is where Milner greets the "junkyard dog,” jumps the fence and then he and Carol stroll through the wrecking yard and talk about racing and car crashes. Rusty's was cleared away not long after the film was completed. It remained vacant for years and today is the site of an industrial park.</p>
            </div>

            <div className="col-12">
                <h5>Inside the Arcade</h5>
                <p>Pinole; San Pablo Avenue near Walter Avenue
                <br/>
                The arcade and miniature golf course where the Pharaohs break open a couple of the pinball machines to steal money for gasoline, while Curt talks to the owners who are members of the Moose lodge that gave him a $2000 scholarship.</p>
            </div>

            <div className="col-12">
                <h5>The Canal, Lover's Lane</h5>
                <p>Marin County
                <br/>
                A tributary of the Petaluma River at the base of the first hill immediately north of the Novato airport. The scenes where Toad and Debbie, and Steve and Laurie go to 'park' and where Debbie and Toad think they hear the "goat killer".</p>
            </div>

            <div className="col-12">
                <h5>Carol’s Home</h5>
                <p>Sonoma
                <br/>
                We aren’t printing the exact address with respect to the homeowners who, I’m sure, have been bothered too many times already. The scene where Milner drops Carol off at her home (231 Ramona in the film).</p>
            </div>

            <div className="col-12">
                <h5>XERB Radio Studio</h5>
                <p>Berkeley; between I-80 and Bay Street, just north of Ashby Avenue
                <br/>
                KRE 1400 AM: Curt enters the radio station through a door with a porthole window that is surrounded by lightning bolts and then goes into the broadcasting studio and talks to the popsicle eating DJ who claims he is not The Wolfman. As he is leaving, Curt learns that the DJ is Wolfman Jack as he sees and hears him talking through the studio window. Click here to read the storied history of KRE-AM, which began broadcasting in 1922. Update:  The original building for KRE radio have been sold and is no longer the home of the Bay Area Radio Museum.</p>
            </div>

            <div className="col-12">
                <h5>The Airport</h5>
                <p>Concord; Buchanan Field
                <br/>
                The location of the final scene in the movie where Curt bids goodbye to his family and friends and then boards Magic Carpet Airlines for his flight to college. The Douglas DC-7 aircraft used for this scene was formerly owned by the band Grand Funk Railroad.</p>
            </div>
          </section>

      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Filming;