import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Impala() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '58 Ipaula" tagline="Cars of American Graffiti" backgroundImage=""/>
      <div className="container">
        <h1>The '58 Ipaula</h1>
        <p>
          Owned by Mike & Sandra Famalette, Colville, WA
          <br/>
          <br/>
          As a teenager, about 35 years ago, I was ready to buy my first car. I didn’t have much money so I looked at a few wrecks and in several car lots. Then, while reading the San Francisco Chronicle, I ran across an ad in the classifieds about a special sale of some movie cars. I called the phone number listed and spoke to Henry Travers, the film’s transportation manager.  The next day I went to the bank and emptied my account. Then my dad and I drove to Sonoma where I met Mr. & Mrs. Travers, who were both super nice to me. After looking at all of the cars, I decided on the Impala and despite the fact that I was able to get Henry to reduce the price a little, I still didn’t have enough money and had to borrow $10 from dad. I remember Henry laughed at me because I wasn’t used to driving a stick. On the way home, the engine overheated and one of the Cadillac taillights fell off, so I had to turn around and go get it. I drove it every day to and from school. Shortly after graduating, I enlisted in the Marine Corps, so the Impala ended up parked for quite some time while I was away serving my Country. I feel so fortunate to have chanced on that ad and have enough money to buy this now legendary car. Over the years, many friends have helped with repairs. Fixing the drive train was my daughter Ashley’s senior project. My son, Matt, and Ashley helped move the car from Northern California to Colville, Washington. Matt worked for days cleaning it and my wife Sandra has always helped, also. It’s still pretty much as it was in the movie.
        </p>

      </div>
    </React.Fragment>
  );
}

export default Impala;