import React from "react";
import PageBanner from "../../../components/PageBanner";
import useScrollUp from "../../../utilities/useScrollUp";

function DuceCoupe() {
  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '32 Deuce Coupe" tagline="Cars of American Graffiti" backgroundImage="../../images/film/coupBanner.png"/>
      <div className="container">
        <h1>The '32 Deuce Coupe</h1>
        <p>
          George Lucas wanted a car that would evoke circa 1962 memories of the cruising he experienced growing up near Modesto.  He and movie producer Gary Kurtz whittled a list of potential car candidates down to a red, full-fendered coupe, finally basing their selection on its chopped top.
          <br/>
          <br/>
          Henry Travers, the film’s transportation manager, was given the enviable task of overseeing the coupe’s reconstruction as a bona fide hot rod.  Henry trailered the car to Bob Hamilton’s shop in Ignacio for the conversion. Lucas wanted a highboy with bobbed rear fenders to emphasize the fender laws that hot rodders continually confronted more than 50 years ago.  Reconstruction included the addition of motorcycle front fenders, aluminum headlight stanchions, and chrome plating for the dropped I-beam solid axle. Also, the grill and shell were sectioned a few inches.
          <br/>
          <br/>
          Next, it was trailered to Close Orlandi’s Body & Fender in San Rafael for its coat of Canary Yellow lacquer paint. The interior, which was red and white tuck and roll Naugahyde, was dyed black. Johnny Franklin’s Mufflers in Santa Rosa added the Man-A-Fre intake manifold, a quartet of Rochester 2GC two-barrel carbs, and no name valve covers to the 1966 327 Chevy motor with fuelie heads.  Franklin’s also built and installed the Sprint racecar-style header-exhaust system based on dimensions provided by Mr. Lucas. The remainder of drive train included a Super T-10 four-speed and 4:11 gears in a ‘57 Chevy rear end.
          <br/>
          <br/>
          You can still see the holes in the frame for the special removable platforms used by the camera crew for close-up filming of the street cruising scenes. The prop department made the THX 138 license plate as a play on the THX 1138 science fiction film made by George Lucas while in film school at USC in Los Angeles. This letter-and-number combination was also used on a jeep in a scene (along with Mr. Lucas) in Francis Coppola’s 1979 movie Apocalypse Now. Prior to More American Graffiti (1979), the Coupe went back to Orlandi’s shop for a new paint job; same color but acrylic enamel replaced the lacquer.
          <br/>
          <br/>
          In 1983, after the More American Graffiti movie, Steve Fitch acquired the Coupe in a sealed auction bid. He had previously acquired the movie’s black ‘55 Chevy.  In 1985, Rick Figari purchased the Coupe.  Rick then entrusted the Coupe to Roy Brizio’s shop in South San Francisco to make it road worthy again. Work included rewiring and rebuilding the T-10 four-speed, aluminizing the chrome headers, and making stronger front fender brackets. The 327 was also rebuilt. A funny note about the coupe:  After the film was finished, they tried to sell it and couldn’t get the asking price of $1,500. Today, both the '32 Ford Coupe and a clone of the '55 Chevy are owned by San Francisco resident Rick Figari.
        </p>
      </div>
    </React.Fragment>
  );
}

export default DuceCoupe;