import React from "react";
import styled from "styled-components";


function ImageWithCard (props) {
  return (
    <Card className="card">
      <img className="card-img-top" src={props.image} alt={props.altText}/>
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <CardText className="card-text">{props.description}</CardText>
      </div>
    </Card>    
  );
}

const Card = styled.div`
  background-color: transparent;
  color: #1A2930;
  margin-bottom: 2vh;
  border: none;
  text-align: center;
`

const CardText = styled.p`
  text-align:left;
`

export default ImageWithCard;