import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function CopCar() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The Cop Car - Clone" tagline="Cars of American Graffiti" backgroundImage="../../images/film/copBanner.png"/>
      <div className="container">
        <h1>The '61 Ford Galaxie Cop Car - Clone</h1>
        <p>
          Created & Owned by Cruisin’ The Boulevard, Inc.
          <br/>
          <br/>
          We don't remember when the idea to build an American Graffiti Cop Car and recreate the destruction scene came up or whose idea it was, but we do remember discussing it with Henry Travers before he passed away in August 2006. He was responsible for that scene so he was especially looking forward to helping set it up.
          <br/>
          <br/>
          Right after Salute 2007, we decided that 2008 would be the year and started planning immediately because we would need permits, insurance and, of course, the car. First stop was the Petaluma Police Department to make sure they didn't think we'd gone off the deep end, they liked it! We chose 4:30am on Thursday, May 15, 2008 as the date and time for several reasons: 1) It would have minimal impact on traffic, 2) The original scene was also filmed in the early morning hours. Of course the location would be the same empty lot between the McNear and LanMart buildings where George Lucas originally filmed it.
          <br/>
          <br/>
          Next came the car, a 1961 Ford Galaxie 4 door sedan sporting a 272 V8 motor that we found on Craigslist and purchased on July 26 from a private party in Sacramento. After trailering it back to Petaluma and with the help of many friends and local businesses, we spent the next seven months getting it running and transforming it into our American Graffiti Cop Car. And yet by the time we got it all together, it was just too pretty to destroy, so a second search was on to find what we call “a movie car”, one that looked good from 20 feet away. We found that one, a 1961 Fairlane (a model that is nearly identical to the Galaxie) at Gerdes Auto Wrecking in Cloverdale. Now if you want to read all about the cars and the Cop Car Destruction scene, please visit www.standbyforjustice.com.
          <br/>
          <br/>
          The Cop Car is a real attention grabber wherever we take it, yet on one very special occasion, its B & M Super Chief siren became a focal point. In August, 2008 our board of directors was invited to tour George Lucas’ Skywalker Ranch located in the rolling hills of northern Marin County, so of course we brought along our Cop Car. After touring the archives where the public never gets to go (and which was absolutely fabulous), we were having lunch at the main house when a member of the Lucas Sound staff who was dining at one of the other tables asked if anyone in the room owned the cop car parked out in front. Immediately a dozen or so hands shot up!
          <br/>
          <br/>
          “Does that siren work?” he asked. “Like a screaming banshee”, we responded. Well, they wanted to record it as they didn’t have a period-correct siren sound in their library. So for the next 45 minutes, our board President John drove the car up and down a road with siren blaring, after which we got a tour of the Lucas Sound studio, another truly amazing place.
          <br/>
          <br/>
          Our Cop Car is available for rent, so if you’re looking for something unusual to have at your private party or company function, click here to find out more.
        </p>
        <hr/>
        <h3>A little story about the American Graffiti Cop Car as told by John Furrer</h3>
        <p>As a prelude to my story, on behalf of Cruisin’ The Boulevard’s board of directors, I want to thank everyone who worked on our cop car and unselfishly donated their time and materials. Many of them are recognized below and we encourage you to patronize their businesses!</p>
        <div class="row">
          <div class="col-sm-4 align-items-stretch d-flex">
            <img class="card-img-top" src="../../images/film/3-Sacto-3_under_61.jpeg" alt="under the hood"/>
          </div>
          <div class="col-sm-4 align-items-stretch d-flex">
            <img class="card-img-top" src="../../images/film/11-Lights_John.jpeg" alt="putting on the lights"/>
          </div>
          <div class="col-sm-4 align-items-stretch d-flex">
            <img class="card-img-top" src="../../images/film/19-CopCar-CityHall.jpeg" alt="cop car infront of city hall"/>   
          </div>
        </div>     

        <p>
          One of the most memorable parts in the movie American Graffiti is when Curt places the cable on the rear end of the police car in Jerry’s Cherries and then drives by fast in front, so the police car races out only to have the rear end yanked from under the car. We don’t know were George Lucas got the idea to do this, but we all love that he did.
          <br/>
          <br/>
          I don’t remember when the idea to recreate this scene came up nor do I remember whose idea it was; all I know is that the idea took off like an uncontrolled wild fire! I do remember discussing this with Henry Travers before he passed away in August 2006. I had asked him if he would help us perform this stunt again in 2008 and he so was looking forward to it. When Henry passed, he took with him all the secrets of how the stunt was setup. For those that don’t know, Henry was responsible for that scene. He set the car up and planned all that needed to be done so it would come off without a hitch. We hope that we can do it just as well as Henry did.
          <br/>
          <br/>
          The idea that we could pull off this stunt and make it look just like it did in the movie still has some of our board members puzzled. It’s July now and only two months since Salute 2007 concluded and the planning process has already started; we need approvals, permits, insurance and, of course, the car. The first plan of attack was to make a trip to the Petaluma PD to see if they thought we were crazy. When we discussed our plans with all involved, they liked it! A time and date were set that wouldn’t stop traffic flow and cause a big scene downtown, although once the word gets out about this, we kind of expect a big crowd to show up.
          <br/>
          <br/>
          Joetta, Rich (left), and John with owner Lindsay in Sacramento.
          <br/>
          <br/>
          We told her that we would make the car famous, but not how!
          <br/>
          <br/>
          Next came the car; a 1961 Ford Galaxie 4 door sedan. All our contacts were notified that we were looking for a car, cheap! Ads were placed on Craigslist to see if someone had our car. About a week after our ads appeared, we got an email. “I have this 2 door Galaxie” or “would you be interested in a Galaxie Wagon?” Finally someone emailed us, “if you look on Craigslist in the Sacramento area, someone has a Galaxie for sale”. A quick look and a phone call confirmed that it was a Galaxie. Lindsay, the owner, answered the phone, “you do know that it’s a 4 door?” “Yes, I’m looking for a 4 door and the pictures show a 4 door.”
          <br/>
          <br/>
          “Well I’m just making sure because a lot of people who drove all the way over to look at the car said, “Oh it’s a 4 door. I want a 2 door.” I guess pictures don’t count or the people looking at the pictures can’t count!
          <br/>
          <br/>
          Ok where do we hook the tow cable?
          <br/>
          <br/>
          You know what they say, three heads are better then one!
          <br/>
          <br/>
          A meeting time was set, my brother’s car trailer hooked up, and myself and three of our board members made the trip to Sacramento to check out this beauty. We arrived on time and met with the owners, Eric and Lindsay. His reason for selling was one that many of us have; too many projects and no time or place to work on them.
          <br/>
          <br/>
          No keys, no battery meant…no start! A quick jump-start from our portable jump-start box got the motor to crank over. Oops, no gas! The body didn’t look too bad and we found no rust! The car has factory air and factory power windows! After talking it over, we made an offer, but Eric couldn’t make up his mind so we took off to get some lunch and give him time think on it. When we returned he told us that our offer was fine; I think his wife convinced him.
          <br/>
          <br/>
          John and Wayne pull Rich from our new cop car. He just wouldn’t come out!
          <br/>
          <br/>
          “OK, let’s do some paperwork and get this thing loaded.” Paperwork? Where’s the paperwork? He couldn’t find it. No problem, we’ll come back and pick up the car when you get the paperwork, which we did about three weeks later.
          <br/>
          <br/>
          Now I should tell you that we did have some problems loading our trophy onto our trailer. We learned that when you borrow a truck and trailer from your brother, you should check to make sure the trailer hitch ball is the right size for the trailer; ours wasn’t and when Rich started to drive the car up onto the trailer, the tongue popped up and almost hit both Wayne and I! After getting past this problem, we learned that the car doors wouldn’t open when it got onto the trailer. We solved this problem by pulling Rich out through the window.
          <br/>
          <br/>
          This guy’s right on my a--! Hey, there’s no one driving!!
          <br/>
          <br/>
          The trip to Petaluma (about 85 miles) was uneventful and when we got home, we unloaded the car, re-inspected it, and parked it in the driveway. Work on it started the next day.
          <br/>
          <br/>
          How long would it take to start this puppy up? A jump-start box, some jumper leads, and a little gas was all it took! It was running and sounding pretty good in no time at all! On our inspection we noted that some items would need to be purchased; new keys, a new fuel pump and a battery box were first. The fuel pump came from eBay, a NOS unit that saved us over $100 from the part’s store. Keys and battery box came from Dearborn parts in Oregon. Once these parts were installed, the car started right up and moved forward under its own power, but it wouldn’t backup. A quick check of the transmission fluid showed nothing on the dipstick, even after adding three quarts! Hmmm!
          <br/>
          <br/>
          It’s a perfect fit for Big John without the seats!
          <br/>
          <br/>
          The trany proved to be our problem child. Everything we did proved ineffective. I tried to adjust the reverse band on the transmission in hopes that would help. This time, even after waiting for warm up, the car didn’t go into reverse. A quick stop at our local transmission shop gave us some bad news and some good news. The bad news was that it seems that this style of transmission, if not adjusted properly, can cause big problems, like a broken case. The good news was that a truck transmission will solve all our problems, if we can find one. This will make a difference if we decide to go to a manual transmission as I have my doubts about finding a truck automatic. I also had to move the car to the other side of my house because I found that the transmission leaks! Time to hit the “junk yards”.
          <br/>
          <br/>
          Well it’s been a few days since I had a chance to do anything with the car. We did get the word out that we were looking for a transmission. Friends! Where would we be in this world without friends? A stop to see an old friend about a different problem netted us a truck automatic. It was just sitting there on his floor taking up space. Somebody’s watching over us; this was just too easy!
          <br/>
          <br/>
          'After' is in the car. you'll have to come see it!
          <br/>
          <br/>
          Well, the old trans came out of the car today. It got hung up on the exhaust pipes for a while, but it’s out on the ground now. We loaded it into my truck, along with the truck trans and headed out to our local transmission shop where we left our treasures in expert hands.
          <br/>
          <br/>
          Our seats looked okay but needed a little help in some spots. A trip to our local upholsters and good friend got the seats looking good. Dirk Tuinstra at Tuinstra’s Auto Glass and Upholstery does a great job (he did the upholstery in my truck) and he donated his time and materials for the Galaxie. Dirk also came over and helped us remove the headliner so we could wire in the lights and siren. If you need anything from a small rip repaired to a full-blown all out special interior, give Dirk a call at (707) 774-2727. Tell him John said to call!
          <br/>
          <br/>
          With the seats out, it was time to remove the carpet for cleaning. After a quick soap and brush, I hung the carpet up and power washed it. Not bad, although I think I will have to add some donut and coffee stains later! The power windows proved be our second big problem. The motor in the driver’s door is rusted beyond repair, so it’s no good. My plan is to check out the remaining three motors to see if I can get two to work for the front doors.
          <br/>
          <br/>
          Well, I finally got the other three motors out of their doors. Can you believe that none of them worked? A change in plans. Let’s install manual window regulators in the front two doors and forget the back. It’s a LOT cheaper (a NOS window motor on eBay went for $400 and I need at least two if not four or them). Believe it or not, I found the horn ring we needed on Ebay! After contacting the owner to make the payment, I found that he was parting out a 61 four door! I got the front window regulators, trunk lock, and right rear door handle. Things are looking better!
          <br/>
          <br/>
          Since I had no idea how long the car had been sitting, I decided to drop the fuel tank to have it cleaned so we wouldn’t have any problems down the line. After getting the tank down, it rattled! A quick check found what was left of a locking gas cap. The tank was very clean inside so no cleaning will be needed.
          <br/>
          <br/>
          Things are starting to look a little different. The front carpet has been cleaned and reinstalled. I even put in a new antenna in hopes that the radio would work. A turn of the key and a quick flick of the volume knob resulted in nothing; no music, no sound. Oh well, can’t win them all. On to another job and all of a sudden I’m listening to music. I forgot this radio has tubes and takes time to warm up. When was the last time you had to wait for a radio to warm up? I installed the rear door handle, front window regulators and the trunk lock and everything works great. It only took me about an hour to do it all! There’s nothing like working on a classic car.
          <br/>
          <br/>
          Think we should strap on a GMC 6-71 and a set of headers?
          <br/>
          <br/>
          Had a little time so I thought I’d do a check on the motor. I had purchased new tune up parts so in they went. Plugs, rotor, cap, condenser, and points (points?) are all new now. After a quick start up, it was time to adjust. Out with the dwell meter (dwell meter?). Very close but not quite right. A quick tweak and all was right on. Time to adjust the carb, but I couldn’t get it to idle very smoothly. Oops, just found a vacuum leak, which was corrected to produce a very smooth idle and a quick response when you crack the throttle. Nothing like “old time power” even if it’s only a two-barrel. Of course, the dual exhaust with short glass packs just begs that big block across the street to see who’s fastest! Maybe later?
          <br/>
          <br/>
          We got our transmission back from the shop today and I started work on it. I should probably tell you why we needed a truck transmission. It’s called “Haz Mat”. When they pulled the rear end out in 1972, they didn’t worry about spilling any oil. We have to worry! We needed a transmission that had a flange mount on the back instead of a slip joint, which means that no oil will be spilled when our driveshaft comes out.
          <br/>
          <br/>
          Drive Shaft King Brad doing his thing!
          <br/>
          <br/>
          The installation of the trany went well, almost. It seems that there is a little difference between the old trans and the new one. The speedo is completely different and hooks up on the other side. No problem, we won’t have a working speedo …remember this if we pull you over for speeding! I also found the cooler lines hooked up in different spots. A little bending and pulling got them secured in their new homes. Time to add fluid; type F. Who keeps type F fluid around? Off to the parts store!
          <br/>
          <br/>
          Since we used a truck transmission, we will need a new driveshaft. I called my good friend, Brad at Drive Line Services in Santa Rosa. He cut, welded, spliced, and balanced our new shaft. Brad is a fellow street rodder and has a very nice ‘37 Sedan. If you’re building a car and need a new drive line, give Brad a call at (707) 528-7886. He’s the best!
          <br/>
          <br/>
          Things are really starting to come together now. With our new transmission and driveshaft, our car moves both ways now, forward AND backward. Now I won’t have to put it in neutral and let off the brake so it will roll backward into the street.
          <br/>
          <br/>
          On a trip to the Petaluma PD for a quick update on our show, we were offered use of official Petaluma Police Car decals for the doors. We ’kind of’ declined the offer as we have something special in mind. You’ll just have to wait to see for yourself!
          <br/>
          <br/>
          B & M Siren owner Kevin O'Connell working on our Super Chief
          <br/>
          <br/>
          During our search for the two key ingredients that will make our car a cop car, we were very fortunate to find two sets of the correct red lights for the roof; exactly the same as in the movie. We bought one from the Sirenman and the other on eBay. Between the two, we were able to make one good set.
          <br/>
          <br/>
          The other piece was the siren; a B & M Super Chief. Rich actually visited their shop in SoCal last year as we had decided to get a non-working unit from them, put it on the roof and put one of those electronic jobs under the hood. Not the same as the real deal, but it would be cheap. A new Super Chief costs $2000! Well, low and behold, there’s our siren on eBay. We waited until the last five seconds to place our bid and we won it!! It was missing the front part and the based was broken, but the seller claimed that it worked, so we had it shipped directly to B & M for testing and repair.
          <br/>
          <br/>
          So now we need to do some wiring. A lengthy call from our siren rebuilder got me a wiring diagram for the siren that showed the way they used to wire them. All you car people will understand when I tell you that I had all the special parts needed to install the wiring in my garage. I actually used some of those parts that I had stored away a long time ago with the idea that one day I MIGHT need them. Now I have to find replacements for them for my “I MIGHT need them again someday” inventory!
          <br/>
          <br/>
          A little to the left.no, a little to the right.perfect!!
          <br/>
          <br/>
          In the middle of wiring, my good friend Don came over and I put him to work. Ask him how hard it is to pull a 6-gauge wire up through the windshield pillar! Well, we finally got it done and I just couldn’t wait to hook up the lights. Rich showed up the next day and he couldn’t wait either, so we did it. Of course we HAD to go watch the movie it to see where to correctly place the lights. Okay, so we enjoyed our short break watching the film again! Once we figured out were they went, we drilled, bolted, and wired them in. Worked just like in the movie! Watch out Milner, we’re coming for you!
          <br/>
          <br/>
          The beginning of beauty at Figone's Truck & Auto Painting
          <br/>
          <br/>
          We got almost everything we needed done, so it was off to the body shop. Another good friend and past sponsor, Steve Figone, volunteered to do the body work and paint. Figone’s Truck & Auto Painting does great work on cars, but his specialty is big rigs and trailers. We will be working with Steve and his crew to get this car done and on the road as soon as we can. If you have a damaged vehicle, no matter how small or how big it is, go see Steve. He can handle it and make it look just like new! You can reach him at (707) 766-8974.
          <br/>
          <br/>
          Well, with our cop car in the body shop getting its new coat of paint, we have been busy collecting some of the other goodies to make our car complete. We have license plates being made…the same number used in the movie, but I don’t think you ever see either one. We just happened to have a still shot that shows the rear of the car! Also, the door and trunk decals are ready thanks to Barber Sign Company.
          <br/>
          <br/>
          After some searching, we located the 102” tall radio antenna. Along with the mounting brackets and whip spring this will go on the left rear bumper, just like it was in the movie. One of the other things that you didn’t see in the movie was the two-way radio, I found a vintage model and will be installing it just for the “look” of it! After cleaning it up, making some brackets and a little paint, this will be a great addition to our cop car.
          <br/>
          <br/>
          Lt. Tim Lyons: "Wow! This siren is cool. It looks like a jet engine!"
          <br/>
          <br/>
          I just got home from attending a couple of meetings and found two boxes on my doorstep. Wow…it’s our Super Chief and does it look awesome! I can’t wait to hear it….we have been warned these are super loud. Down to Figone’s tomorrow to place it on the roof for some photos. It’s getting closer and I can hardly stand the wait!
          <br/>
          <br/>
          Steve’s crew, Martin, Jesus, and Jose have been doing a great job on our car and although it’s not done, I can see the finished product in my head. I placed the siren on the roof and added the lights. I didn’t have our good pair of lights and I had to use our ok pair. As you can see in the pictures, one light is missing its base. Even though the car isn’t painted, placing the lights and siren on the roof makes brings back memories of the movie and the early 60’s.
          <br/>
          <br/>
          If Webster’s Dictionary had a listing for Movie Prop, it may go something like this: Movie Prop - an item used in a movie or on a movie set; an item that looks good on camera but is really just a hollow shell; an item that only the parts seen by the movie camera look good; a fake, not real……I’m sure you get the idea. Our cop car started out as a movie prop but somewhere along the way we got carried away. It’s been hard for a car guy like me to build this car into a movie prop; I have a special interest in the vehicle. I want to see this car become something special interest, so, even though we are going to use it in our car scene, it’s NOT a movie prop!
          <br/>
          <br/>
          Ok, this is a guy thing but I’m sure that the wife’s will all understand. My good friends and fellow directors Wayne and his wife Rhonda came over today and after a short conversation, we all loaded into his truck for a little trip. I’m not sure if we told our wives where we were headed or not but I’m sure if we had told them, it would have been just Wayne and I. We’re going wine tasting….well, at least we drove through the wine country on our way to Gerde’s Auto Wrecking in Cloverdale. This is Sonoma County where the real good wine is made! Over the hills in Napa…isn’t that where they make auto parts??
          <br/>
          <br/>
          Wayne's end must be a lot lighter than John's??
          <br/>
          <br/>
          We needed a rear bumper and a few small parts for the cop car and Gerde’s had a ‘61 Fairlane. When we arrived we were already in trouble because not too many wives think a trip to a wrecking yard is even worth getting out of the house. Gary, the owner, showed us the bumper and then let us wander around. It’s been a long time since I’ve been able to walk around a yard like that and had it been night, I would have felt just like John Milner walking with Carol. We didn’t find anything else we needed but we did take some mental notes for future projects. We talked with Gary for what seemed like just a few minutes and then Wayne and I carried the bumper off to the truck. Don’t think a trip to a wrecking yard, no matter how neat, could get Wayne and I off the hook. We promised to take the ladies out to lunch, and we did…to Carl’s Jr.! Hey, we didn’t say where did we? When I got home, I cleaned up the bumper and loaded it into my truck.
          <br/>
          <br/>
          Another trip out to Steve’s to get the latest on our cop car and drop off the bumper. The car’s been moved into the paint area and the masking has started. I can’t wait until they’re done and the car moves back to my house for the final touches. Waiting for the car are the decals, radio, antenna, and a few other items, I’m having dreams about how good the car is going to look. I know it’s just a clone but it looks like we did it with the genes from the original car! I know that Henry Travers would be proud and hopefully Mr. Lucas, also!
          <br/>
          <br/>
          It’s really taking shape now! I entered Steve’s shop and was surprised to see the car in primer. Boy, does it look good! Steve’s crew is just waiting for the primer to dry to do the final block sanding. I even tried a little sanding when no one was around (You’ll have to redo that fender…Sorry Steve). Where’s the black and white paint? Oops, we didn’t buy it yet because we’ve been hoping to get it donated. Well, thanks to generosity of manager Troy his staff at Freeman Toyota Collision Center in Santa Rosa we now have all the paint we need. Although I can’t tell you yet, while picking up the paint, we heard some very exciting news about our cop car!
          <br/>
          <br/>
          Oh, oh… I think this paint is still wet!
          <br/>
          <br/>
          It’s been a while since I’ve had time to update this story, but I have been going down to Steve’s shop almost daily to check out the car. In the last few days, it has really started to look like a police car. The first coat of paint went on; white doors and roof. It’s hard to imagine the black because of all the tape and paper wrapping.
          <br/>
          <br/>
          I returned the next day with Susan Villa, one of our board members who wanted to get a peek. That’s her in the picture testing to see if the paint is dry. Steve will remove the fingerprint she left at a later date! I did have to have a talk with Steve because I noticed something that bothered me. There was a heavy chain sticking out from under the front end of the car. I told Steve to tell his crew that Curt used a cable and that he attached it to the REAR end!
          <br/>
          <br/>
          Thanks a million, Steve!
          <br/>
          <br/>
          Black is on the car, but I’m upset! I wanted to get some photos while it was still masked, but darn it, they didn’t come out! It will have to wait until tomorrow when we get the car!! Is it Christmas? I feel like a kid who asked for a brand new bike. I know I won’t be able to sleep tonight.
          <br/>
          <br/>
          Several of us arrived at Steve’s shop early to find the car unmasked and his crew was working on getting all the stuff put back on. I tried to help but I just seemed to be in their way. It finally rolled out and into the sun. Wow!! A few more pictures, a grateful handshake and we were on the way home, at least for two blocks, which is when the car ran out of gas!! OK, I can’t read a gas gauge but even if I could, I still wouldn’t have made it to the gas station. It was 5 blocks away! In about 15 minutes, Rich, who was right behind me, had returned with the go juice and we were off again.
          <br/>
          <br/>
          Nice buns!
          <br/>
          <br/>
          Home….at last and along with Wayne, Rhonda, Rich, and Joetta we started busting buns to finish whipping the beast into beauty. Jeff joined us later as did Dirk, our upholstery guru, who reinstalled the headliner. We even had a young man, Mario Grossi whose parents have a car entered into our show, stop by to help us. We worked as a team and everyone seemed to know exactly what to do and did it. My first job was to fix the brake lights and then we installed the lights and finally the siren, which turned out to real pain because of the Allen bolts I was using. We finally got it and everyone wanted to hear it so after connecting the power, EVERYONE heard the siren, and I mean EVERYONE; even people on the other side of town!! The flashing lights work so let’s go catch some speeders! Joetta says no! OK. Tomorrow we drive it down to its Petaluma Debut. I wonder what would happen if I hit the lights and siren on the way? No waiting at lights, no slow drivers in front of me, everyone clearing the road just for me! Hmmmm…interesting!
          <br/>
          <br/>
          Awesome! Here’s the optional jet assist interface.
          <br/>
          <br/>
          Today we debut the car at our new downtown Theatre Square to whom we’re very grateful for sponsoring the debut! The car’s ready, our club’s ready, I’m ready, so lets hit the road! We loaded my ‘55 Chevy truck with all the stuff we need to set up for the debut and headed downtown. Lots of board members showed up to help, then at 10am, Chev-Ron drove me home to pick up the car so I wouldn’t be late for my 10:45am appointment with our Mayor, Pam Torliatt, whom we promised could drive the car down to the debut.
          <br/>
          <br/>
          Waiting peacefully outside of City Hall. Where’s the Mayor?
          <br/>
          <br/>
          When Mayor Pam arrived at City Hall, about a minute after I did, she was blown away at how cool the car looked. She was accompanied by her mom and former Mayor, David Glass. I explained to her that the car was older then she and that it did NOT have power steering or power brakes. I also explained how to turn on the flashing roof lights and how to work the siren. She hit the lights right away but I held off arming the siren until we got a little closer to the theater district. As we slowly traveled down Western Avenue, we had to wait as people blocked the crosswalk to stare. Finally we turned the corner onto Petaluma Boulevard and I armed the siren. It wasn’t but two seconds later that she pushed on the horn ring and the siren blasted its piercing wail as only a Super Chief can do! It echoed though out the downtown area and I’m sure that everyone within a mile knew we were coming. With lights flashing, siren blaring and a smile on her face that stretched from ear to ear, our mayor made all those other cars pull out of her way. It was totally excellent!
          <br/>
          <br/>
          Out of my way.I mean, excuse me, this is official business!
          <br/>
          <br/>
          When we reached the theater district, our camera crews had stopped northbound traffic so they could stand in the street to film the car driving down the Boulevard. A few quick turns and we arrived inside Theatre Square. As we exited the car and the camera flashed, I felt like a movie star arriving at the Academy Awards! We spent the next 15 minutes taking pictures and then someone said we should live up to our name and Cruise The Boulevard! We loaded the car with as many people as we could and headed out. This time I was driving with Mayor Pam riding shotgun.
          <br/>
          <br/>
          Heading north on Petaluma Boulevard, who do we pull up behind at the signal but Steve Figone! When the light turned green and we started moving, I hit the lights and siren. Steve just waved. We followed him all the way up Mayor Pam.leader of the pack!
          <br/>
          <br/>
          Petaluma Blvd until the one lane became two where he moved into the slow lane. I wanted to pull up next to him but there was a car in front of me and I couldn’t. Suddenly that car cut right in front of Steve; I guess I had forgotten to turn off the flashing red lights. Sorry Steve!
          <br/>
          <br/>
          We drove all the way up to the police department and pulled right in front of the front door and hit the siren. We wondered how many officers dropped their donuts when they heard that B & M siren scream. I guess they were busy picking up the donuts because no one came out. Oh well, back downtown and I hit the siren again. Traffic pulled to the right and let us through. I looked at Pam and said “I like this; I should have been a police officer”. A quick turn and we cruised back into the theater district.
          <br/>
          <br/>
          David get's a farewell smooch from Sheri before being hauled off to the pokie!
          <br/>
          <br/>
          We parked the car right off the Boulevard, just like it was in Jerry’s Cherries waiting for the Pharaohs to go speeding by. Of course, they didn’t. We let parents put their kids inside to take photos and then some of our board members took some crazy pictures. When Chris Linnell, aka The Wolfman, showed up, the photos got even crazier. There were even some back seat photos that reminded me of Debbie and Toad in the ‘58 Impala down at the Canal!
          <br/>
          <br/>
          At 3pm, we packed everything away and headed out. What a great day except for the weather, which had been cold and rainy. The people who showed up had a great time and the Theatre Square merchants were fantastic including Will at Powell’s Candy Store with the warm chocolate drink that really hit the spot on a cold morning and Jennie Low from Jennie Low’s Chinese Cuisine for the giving us all a great hot lunch. A special thank you to Karla at Haus Fortuna for rallying all of the merchants and Basin Street to get behind this event and Salute 2008. We also thank everyone who donated raffle prizes and Basin Street Properties for sponsoring the debut at Theatre Square.
          <br/>
          <br/>
          Cruisin’ The Boulevard members at Theatre Square, downtown Petaluma.
          <br/>
          <br/>
          Finally, we are especially indebted to all of the sponsors who helped us put our cop car together. Without your support, this car would still be sitting in someone else’s driveway looking for a new owner.
          <br/>
          <br/>
          So, the next time you’re traveling down Petaluma Boulevard, if you see two red lights and a large shiny siren in your rearview mirror, beware! It may not be Cruisin’ the Boulevard, but instead our local police force using the car undercover looking for speeders. Remember, this car has old time horsepower and it was put together by old time hot rodders. Now, were did I put those “POLICE INTERCEPTOR” emblems!
          <br/>
          <br/>
          See ya on the streets or maybe in the skies!
          <br/>
          <br/>
          John “The Rocketman” Furrer
        </p>

      </div>
    </React.Fragment>
  );
}

export default CopCar;