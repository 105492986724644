import React from "react";
import PageBanner from "../../components/PageBanner";
import LeftCarsCard from "../../components/LeftCarsCard"
import RightCarsCard from "../../components/RightCarsCard"

import useScrollUp from "../../utilities/useScrollUp";

function Cars() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The Cars" tagline="of American Graffiti" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
      <h1>The Cars of American Graffiti</h1>
      <LeftCarsCard 
        image="../images/film/carSquare.jpeg"
        altText="The '32 Deuce Coupe"
        title="The '32 Deuce Coupe" 
        text="George Lucas wanted a car that would evoke circa 1962 memories of the cruising he experienced growing up near Modesto.  He and movie producer Gary Kurtz whittled a list of potential car candidates down to a red, full-fendered coupe, finally basing their selection on its chopped top." 
        link="cars/duceCoupe"/>
      <RightCarsCard 
        image="../images/film/55chevy.jpeg"
        altText="The '55 Chevy"
        title="The '55 Chevy" 
        text="There were three black ‘55 Chevys used in the film, two of which were also used in the 1971 movie Two Lane Blacktop.  The one used for exterior shots for both movies and driven by Harrison Ford in American Graffiti had a fiberglass shell that covered a 427 motor coupled to an M-22 Muncie transmission and 4.88 Olds rear-end." 
        link="cars/chevy"/>
        <LeftCarsCard 
        image="../images/film/58impala.jpeg"
        altText="The ’58 Impala"
        title="The ’58 Impala" 
        text="While reading the San Francisco Chronicle, I ran across an ad in the classifieds about a special sale of some movie cars. I called the phone number listed and spoke to Henry Travers, the film’s transportation manager.  The next day I went to the bank and emptied my account." 
        link="cars/impala"/>
      <RightCarsCard 
        image="../images/film/56tbird.jpeg"
        altText="The '56 T-Bird"
        title="The '56 T-Bird" 
        text="Clay and May Daily purchased their '56 Thunderbird convertible in 1964 from a San Bernardino, California Ford dealer’s used car lot. It was red and had 55,000 miles on the odometer. Several years later they moved to Petaluma and had it painted white. Ironically, they lived on Joan Drive, only about four houses away from Cruisin’ The Boulevard Board Member John Furrer who at the time, of course, wasn’t acquainted with them." 
        link="cars/tbird"/>
        <LeftCarsCard 
        image="../images/film/61cop2.jpeg"
        altText="The '61 Ford Galaxie Cop Car - Clone"
        title="The '61 Ford Galaxie Cop Car - Clone" 
        text="We don't remember when the idea to build an American Graffiti Cop Car and recreate the destruction scene came up or whose idea it was, but we do remember discussing it with Henry Travers before he passed away in August 2006. He was responsible for that scene so he was especially looking forward to helping set it up." 
        link="cars/copCar"/>
      <RightCarsCard 
        image="../images/film/51merc1.jpeg"
        altText="The '51 Mercury"
        title="The '51 Mercury" 
        text="Thanks to Steve Rowan of Ontario, Canada and Rick Blevins of Overland Park, Kansas, we have been able to piece together information about what happened to the Merc after filming was completed in 1972." 
        link="cars/mercury"/>
        <LeftCarsCard 
        image="../images/film/67citroen.jpeg"
        altText="The '67 Citroen 2CV"
        title="The '67 Citroen 2CV" 
        text="One classic car featured in the fleet of motors in iconic 1973 coming-of-age classic movie 'American Graffiti' is considerably more quirky than cool." 
        link="cars/citroen"/>

      </div>
    </React.Fragment>
  );
}

export default Cars;