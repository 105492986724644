import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";

function Music() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Music" tagline="more than a soundtrack" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
        <h1>The Music of American Graffiti</h1>
        <p>
          American Graffiti is one of the first films to use music almost exclusively from records. Initially Mr. Lucas selected almost 80 popular "era" songs from his personal collection, but the $80,000 budget could only afford 41 licenses, so many songs were scrubbed including Elvis Presley's. He used music to create a realistic, documentary feel, whereas in most films, music is used to build drama.
          <br/>
          <br/>
          Sound artist Walter Murch re-recorded all of the songs to create the reality of the situation. Speakers were placed between buildings, on streets, in the gym, etc., so that, for example, music coming from a passing car actually sounded so.
          <br/>
          <br/>
          When Laurie and Steve begin the snowball dance at the Freshman Hop dancing to Smoke Gets In Your Eyes, the music enhances the viewer's perception of the emotional situation between the two of them. Acoustically, it begins with a big gymnasium feel. Then, as the scene focuses on their intimacy, it is compressed to sound more like what people would hear inside of their heads.
          <br/>
          <br/>
          The music (Barbara Ann) reaches its most ethereal crescendo in the scene where, after Curt is kicked out of Bobbie's car, he runs up the street in an attempt to catch the white T-bird and ends up standing alone in an intersection (Western and Kentucky Streets in front of what is now Couches Etc., Petaluma).
        </p>
        <h3>Song List</h3>
        <ul>
          <li>Rock Around the Clock --- Bill Haley & the Comets</li>
          <li>Sixteen Candles --- The Crests</li>
          <li>Runaway --- Del Shannon</li>
          <li>Why Do Fools Fall in Love --- Frankie Lymon & the Teenagers</li>
          <li>That'll be the Day --- Buddy Holly</li>
          <li>Fanny Mae --- Buster Brown</li>
          <li>At the Hop --- Flash Cadillac & the Continental Kids</li>
          <li>She's So Fine --- Flash Cadillac & the Continental Kids</li>
          <li>The Stroll --- The Diamonds</li>
          <li>See You in September --- The Tempos</li>
          <li>Surfin' Safari --- The Beach Boys</li>
          <li>He's the Great Imposter --- The Fleetwoods</li>
          <li>Almost Grown --- Chuck Berry</li>
          <li>Smoke Gets in Your Eyes --- The Platters</li>
          <li>Little Darlin' --- The Diamonds</li>
          <li>Peppermint Twist --- Joey Dee & the Starlighters</li>
          <li>Barbara Ann --- The Regents</li>
          <li>Book of Love --- The Monotones</li>
          <li>Maybe Baby --- Buddy Holly</li>
          <li>Ya Ya --- Lee Dorsey</li>
          <li>The Great Pretender --- The Platters</li>
          <li>Ain't That a Shame --- Fats Domino</li>
          <li>Johnny B. Goode --- Chuck Berry</li>
          <li>I Only Have Eyes for You --- The Flamingos</li>
          <li>Get a Job --- The Silhouettes</li>
          <li>To the Aisle --- The Five Satins</li>
          <li>Do You Wanna Dance --- Bobby Freeman</li>
          <li>Party Doll --- Buddy Knox</li>
          <li>Come Go with Me --- The Del-Vikings</li>
          <li>You're Sixteen, You're Beautiful --- Johnny Burnette</li>
          <li>Love Potion No. 9 --- The Clovers</li>
          <li>Since I Don't Have You --- The Skyliners</li>
          <li>Chantilly Lace --- The Big Bopper</li>
          <li>Teen Angel --- Mark Dinning</li>
          <li>Crying in the Chapel --- Sonny Till & the Orioles</li>
          <li>A Thousand Miles Away --- The Heartbeats</li>
          <li>Heart and Soul --- The Cleftones</li>
          <li>Green Onions --- Booker T. & the M.G.'s</li>
          <li>Only You (and You Alone) --- The Platters</li>
          <li>Goodnight, Well It's Time to Go --- The Spaniels</li>
          <li>All Summer Long --- The Beach Boys</li>
        </ul>

      </div>

    </React.Fragment>
  );
}

export default Music;