import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Poremba() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Rich Poremba" backgroundImage="../../images/memorials/porembaBanner.png"/>
      <div className="container">
        <h1>Rich Poremba</h1>
        <h3>March 26, 1944 to September 19, 2010</h3>    
        <p>
          If you attended any of the past Salutes, you may have seen Rich as he worked the check-in cue when you entered the event to park your car. Whether you knew Rich or not, if it wasn’t for him, there would be no Salute or car show. It was Rich who took the idea for this show and started the non-profit, doing all the required paperwork. Rich was the kind of person who didn’t let anything get in his way, he always had an answer or knew how to get the hard things done. He was a dedicated volunteer, whether it be for the 14 years he volunteered for the Boys and Girls Club or for the 6 years he volunteered with us, he loved ever minute of it. Our 2011 event will be dedicated to him and we will remember him at all future events. He was a “car guy” and he loved it! He was one of our founding board members, a friend, and mostly, part of our family. His memory will live on in the ones he left behind. Thank you Rich! We miss you and we know that you are watching over us.
        </p>
      </div>
    </React.Fragment>
  );
}

export default Poremba;