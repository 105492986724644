import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";


function MemorialCard (props) {
  return (
    <Card className="card col-xs-5 col-sm-4 col-md-3">
      <Dedication to={props.link}>
        <HeadShot className="card-img-top" src={props.image} alt={props.altText}/>
        <div className="card-body">
          <h5 className="card-title"><span style={{fontWeight:700}}>{props.office}</span><br/>{props.name}</h5>
          <div className="card-text">
            <Date>{props.date}</Date>
          </div>
        </div>
      </Dedication>
    </Card>    
  );
}

const Card = styled.div`
  background-color: transparent;
  color: #1A2930;
  margin-bottom: 2vh;
  border: none;
  text-align: center;
`

const HeadShot = styled.img`
  border-radius: 50%;
  width: 100%;
`

const Dedication = styled(Link)`
  color:#1A2930;
`

const Date = styled.p`
  font-size: 80%;
`

export default MemorialCard;