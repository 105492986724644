import React from "react";
import PageBanner from "../components/PageBanner";
import useScrollUp from "../utilities/useScrollUp";
import { Link } from "react-router-dom";
import WolfmanStatement from "../components/WolfmanStatement";

function Show() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Car Show & Events" tagline="Petaluma California's Salute to American Graffiti" backgroundImage="images/welcome/splashBanner.jpg"/>
      <div className="container"> 
        <h1>Next Car Show May 15-17, 2025</h1>
        <p>
          Remember flat tops, dancin’ the bop, and Friday night sock hops? What about white bucks, blue suede shoes and bobby sox? Petticoats, peggin’ your jeans and root beer floats? Goin’ steady, American Bandstand and getting’ your kicks on route 66? Cruisin’ the main drag, and grabbing a coke and a 15 cent burger at Mel’s!
          <br/>
          <br/>
          This is American Graffiti!!
          <br/>
          <br/>
          Join us each year in May as Cruisin’ The Boulevard honors George Lucas’ timeless, coming-of-age film, American Graffiti, most of which was filmed here in Petaluma in the early summer of 1972. Grab your favorite squeeze, jump into your hot rod, candy-colored screamin’ machine or the family car for that matter, and cruise on back to the “happy days” of the ‘50s and ‘60s.
          <br/>
          <br/>
          Petaluma’s Salute to American Graffiti is a non-alcoholic community-wide celebration! It’s family fun for everyone…. from boys and girls to grandmas and grandpas! Proceeds fund our Petaluma Save-A-Life Project, Scholarships and other projects and programs that support arts, education, history, safety and welfare.
          <br/>
          <br/>
          So, put on your poodle skirts, peggers, jeans, white sox and loafers, and come kick up your heels to our live rock ‘n roll bands! Grab a root beer float, see if you can still hula hoop and check out all of the cool American classNameic cars and trucks cruising the same streets where the movie was filmed 40 years ago!
          <br/>
          <br/>
          As the Wolfman would say….. Be there or be square baby!
        </p>
        <section className="contrast">
          <h2 className="center">Register and join the fun</h2>
          <div className="row align-items-center">
            <div className="col-sm-6">
                <div className="contrastText">
                  <p className="align-middle">
                    Ticekts for the 2025 event will go on sale through Eventbrite February 1, 2025 at noon. 
                  </p>
                  {/*<Link className="btn btn-block btn-outline-primary" to={{ pathname: "https://www.eventbrite.com/e/2024-salute-to-american-graffiti-car-show-registration-775315969937"}} target="_blank" rel="noopener noreferrer">Eventbrite Page</Link>*/}
                </div>
            </div>
            <div className="col-sm-6"><img className="mx-auto d-block" src="images/show/registerSquare.jpg" alt="register checkin"/></div>        
          </div>
        </section>
        <section>
          <h2 className="center">The 2025 Event Line Up</h2>
          <h3>Thursday May 15th</h3>
          <hr/>
          <h4>Jumpstart Dinner</h4>
          <p>
            Enjoy an evening of dinner, and mingling at Glenn Ranch. An evening of fun with other patrons, old cars and a fabulous country setting in the outskirts of Petaluma. The money raised at the dinner helps support our scholarship program.
            <br/>
            <br/>
            Event Check in 5:30 pm, Dinner - 6:30 pm Seating is limited and tickets only available in advance.
          </p>
          <p className="right"><Link to="/show/dinner">Jumpstart dinner details</Link></p>
          <h3>Friday May 16th</h3>
          <hr/>
          <h4>Cruise-In and Kickoff Social</h4>
          <p>
           Free to all! Plaza North Shopping Center, North McDowell Blvd. at Washington St 4 – 8pm Cruise-in Social  There will be reserved parking for 1972 and older American vehicles.  American Graffiti collectibles will be available thanks to Insignia Branding.
          </p>
          <p className="right"><Link to="/show/social">Kickoff Social Details</Link></p>
          <h3>Saturday May 17th</h3>
          <hr/>
          <h4>The Car Show</h4>
          <p>
          Cruisin' the Boulevard invites American model cars 1972 or older to join us May 18th for the annual Salute to American Graffiti Car Show. This event features show parking on the streets of historic Downtown Petaluma, the same street featured in the film, old time live radio dj, food vendors.
          </p>
          <p className="right"><Link to="/show/info">Car show details</Link></p>
          <h4>The Cruise</h4>
          <p>
          At 4:00 pm, special lanes are opened up within in the show event for cars to cruise, on the streets where the route was filmed. We have expanded the route to a little over 2 miles!  Attendees are invited to drive the historic strip, parade their vehicle and enjoy the slow roll on our cruise loop. 
          </p>
          <p>
            <strong>CRUISERS:</strong> To cruise the boulevard the vehicle must be registered for the show, and have a valid cruise pass clearly displayed in the windshield.
            <br/>
            <br/>
            <strong>WATCHERS:</strong>(Free no ticket required):4 pm-8 pm. Perhaps the best place to watch the cruise will be along Petaluma Blvd, south of B Street to D Street. Grab your chair, a picnic basket, something to drink, and if necessary, jackets and a blanket. Find a spot to watch the action. Be sure to wave and give the thumbs up to all the cruisers!
            <br/>
            <br/>
          </p>
          <p>More events to be added, please come back for updates.</p>
        </section>


      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Show;