import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';


function RightCarsCard (props) {
  return (
   <Wrapper className="card">
      <div className="row no-gutters">
          <div className="col-8">
              <div className="card-body">
                  <h3 className="card-title">{props.title}</h3>
                  <p className="card-text">{props.text}</p>
                  <Link to={props.link}>Read More</Link>
              </div>
          </div>
          <div className="col-4">
              <img className="card-img" src={props.image} alt={props.altText}/>
          </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin 1% 0;
`

export default RightCarsCard;