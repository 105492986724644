import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Giammattei() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Susan Giammattei" backgroundImage="../../images/memorials/memorialBanner.png"/>
      <div className="container">
        <h1>Susan Giammattei</h1>
        <h3>August 24, 1957 to July 28, 2016</h3>
        <p>
        Susan Giammattei a former board member served as Secretary of Cruisin’ the Boulevard, Inc. Professionally, Susan was the first woman hired by the San Francisco Recreation and Parks Department as a Supervisor at the S.F. Botanical Garden.  An avid classic automobile enthusiast, her first love was her 1960 Chevy Impala which she owned for over 30 years. 
        </p>
      </div>
    </React.Fragment>
  );
}

export default Giammattei;