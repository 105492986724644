import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";

function Scenes() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Behind the Scenes" tagline="American Graffiti" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
        <h1>Behind the scenes</h1>
        <p>
          This page is a work in progress. We’ll continue to add tidbits as we discover more about what went on behind the scenes. Of particular interest are the personal stories that were graciously provided by members of the film's production staff, local residents and owners of cars that were in the movie.
        </p>
        <dl className="row">
        <dt className="col-sm-3">Ford’s cowboy hat</dt>
        <dd className="col-sm-9">Harrison Ford wore a white cowboy hat in the film because George Lucas wanted him to get his hair cut in ‘50s "flat top" fashion. Ford refused because longer hair was the style of the times and he feared that cutting it might affect his opportunities for future acting jobs. He convinced Lucas to allow him wear the hat to cover his hair, which actually contributed to the ambiance of Falfa’s rough and tough personality.</dd>

        <dt className="col-sm-3">I want to be Carol</dt>
        <dd className="col-sm-9">Cindy Williams wanted to play Carol and was even willing to have braces put on her teeth for better effect.</dd>

        <dt className="col-sm-3">The white T-Bird</dt>
        <dd className="col-sm-9">During filming, Clay Daily, owner of the 1956 Thunderbird that Suzanne Somers drives was never more than a few feet away from his prized possession, constantly wiping off the dust. Ms. Somers said that he frequently barked instructions to her, about what to and what not to do.</dd>

        <dt className="col-sm-3">Steve stays home</dt>
        <dd className="col-sm-9">After the drag race crash, the scene in which Steve assures Laurie that he is staying in town and not going away to college with Curt was recorded in one take. Having been released from filming for the day and already in their street clothes, Ron Howard and Cindy Williams had to get back into costume so this scene could be filmed.</dd>

        <dt className="col-sm-3">At the hop</dt>
        <dd className="col-sm-9">During filming of the hop scenes, Ron Howard would come up on stage every few hours and give away door prizes to help keep everyone entertained. Most every time he did, Flash Cadillac would play the theme from the Andy Griffith show. Although he probably wasn’t thrilled about this, he was such a great natured person that he took it in stride.</dd>

        <dt className="col-sm-3">In front of the used car lot</dt>
        <dd className="col-sm-9">In the scene where Toad (Charles Martin Smith) is confronted by the overzealous car salesman, Mr. Smith tripped and fell during one of the shoots. Also, although the police had the street blocked, a car slipped by and one of the cops banged on its hood with his flashlight as it drove by.</dd>

        <dt className="col-sm-3">Cop car destruction</dt>
        <dd className="col-sm-9">The destruction of the police car at Jerry’s Cherries Used Car lot was carefully orchestrated. The entire rear axle was cut away from rest of the car’s frame and body. The cable attached to the axle at one end had the other end attached to the winch on a heavy-duty 10-wheel tow truck that was parked in the darkness at the far end of the lot (not a light pole as portrayed in the movie). When the car sped away from its parked location, at the precise moment when it crossed the sidewalk, the winch was activated. Between the pulling of the cable and the momentum of the forward moving car, the axle was successfully yanked from beneath the car. Until the scene was filmed, the production staff was concerned that the car might actually flip over and land on its roof.</dd>

        <dt className="col-sm-3">The drag race</dt>
        <dd className="col-sm-9">Mr. Lucas had brought donuts for the cast and asked Eddie McCullough, owner of one of the cars in the movie, to keep an eye on them and not to give them away to anyone but the cast. A couple of onlookers asked if they could have a donut and Eddie told them no. Harrison Ford, inside the Winnebago motor home where the cast changed costumes, heard this and came out and said, “Give ’em a God damn donut!”</dd>

        <section className="contrast">
          <h2 className="center">Filming was beset by a series of misfortunes and disasters</h2>
          <div className="col-12">
            <div className="align-middle">
              <ul>
                <li>The day before filming was due to start a key member of the crew was arrested for growing marijuana.</li>
                <li>On the first night of shooting, it took so long to get the cameras mounted onto the cars that filming didn't get started until 2 a.m., putting the crew half a night behind schedule before they'd even started.</li>
                <li>On the second night of shooting a fire in a nearby restaurant brought fire trucks into the area. Their sirens and the resulting traffic jam preventing any filming.</li>
                <li>During the filming of Milner's deuce coupe, assistant cameraman Barney Colangelo slipped off the trailer of the camera truck and was run over, suffering minor injuries.</li>
                <li>Paul Le Mat was rushed to hospital after suffering an allergic reaction to the walnuts in the Waldorf salad he had at dinner one morning.</li>
                <li>Paul Le Mat threw Richard Dreyfuss into a swimming pool, gashing his forehead on the day before he was due to have his close-ups filmed.</li>
                <li>Richard Dreyfuss had wardrobe complaints and refused to wear the loud Bermuda shorts and shirt Lucas had chosen for his character.</li>
                <li>During the filming of the drag race between Milner and Falfa, one of the ’55 Chevy’s axles broke and was replaced. On the second try, the replacement axle broke. On the third try, the car failed to veer off the road as planned, narrowly missing two cameramen lying on the road.</li>
                <li>Where the cast stayed: Cast members roomed at the Holiday Inn in San Rafael. Apparently there was a dentist’s conference that took place during a portion of their stay and they were a pretty routy bunch during the daytime, when most of the cast was trying to sleep.</li>
              </ul>
            </div>       
          </div>
        </section>

        <div className="col-12">         
          
        <hr/>
        </div>
         
        <dt className="col-sm-3">Mayflower Van & Storage</dt>
        <dd className="col-sm-9">Located immediately south of the Chevron gas station on Petaluma Boulevard (now the site of Bank of the West); the old Mayflower building owned by Al Bonetti is where the cars and filming equipment were stored during the daytime. It was also where the cast, crew and car owners were served meals.</dd>

        <dt className="col-sm-3">Cut my hair? Not me</dt>
        <dd className="col-sm-9">When many local teens balked at cutting their hair to fit the style of the era, the casting director got the idea to recruit active duty military personnel. He contacted nearby Hamilton AFB in Novato, California and the information officer there, Lt. Col. Dusty Rhodes put out the call for help in the base newspaper. Several airmen accepted the offer and took military leave to participate.</dd>

        <dt className="col-sm-3">Time saver</dt>
        <dd className="col-sm-9">In order to significantly reduce production time, two cameras were used simultaneously in scenes involving conversations between actors in different cars.</dd>

        <dt className="col-sm-3">Over 1000 cars</dt>
        <dd className="col-sm-9">When ads to fulfill the need for about 300 pre-1963 cars were published in local newspapers, over 1000 car owners responded and were interviewed.</dd>

        <dt className="col-sm-3">Howard & Quinlan</dt>
        <dd className="col-sm-9">American Graffiti is actress Kathleen Quinlan’s first credited screen appearance. As Peggy, Laurie’s friend who tries to comfort her after she and Steve have a fight, Ms. Quinlan, in 1995, stars in Apollo 13, which Ron Howard directed.</dd>

        <dt className="col-sm-3">R2-D2</dt>
        <dd className="col-sm-9">There is a rumor that while director Lucas and a co-worker were editing the film, the co-worker asked Lucas for "reel two, dialogue two", which abbreviated to R2-D2, a name that surfaced in Lucas' 1977 film Star Wars.</dd>

        <dt className="col-sm-3">Keeping the focus</dt>
        <dd className="col-sm-9">Screenwriters Willard Huyck and Gloria Katz wanted an additional title card at the end detailing the fates of the girls, but Lucas refused, arguing it would take away from the film’s focus, which was the four boys.</dd>

        <dt className="col-sm-3">Pick a better name</dt>
        <dd className="col-sm-9">Dissatisfied with the name "American Graffiti", producer Francis Ford Coppola and Universal rep Ned Tanen suggested that George Lucas re-title it "Another Slow Night in Modesto" or "Rock Around the Block". We’re all pleased that Mr. Lucas stuck by his guns!</dd>

        <dt className="col-sm-3">Lousy movie</dt>
        <dd className="col-sm-9">On Sunday morning at 10am, January 28, 1973, with Universal Studios representative Ned Tanen in attendance, the film was previewed to an audience of young people in San Francisco’s Northpoint Theater. In a story that is now legendary in Hollywood annals, despite a very good audience reaction, Mr. Tanan was not impressed with the film and called it "unreleasable". Francis Ford Coppola, enraged at the comment, offered to buy the film from Universal on the spot, while the exhausted and burned-out George Lucas watched in shock. A compromise was finally reached whereby Universal could "suggest" modifications to the movie, a resolution with which Mr. Lucas was not happy because it took control of the film away from him.</dd>

        <dt className="col-sm-3">I want to be a race car driver</dt>
        <dd className="col-sm-9">As a young man, George Lucas aspired to be a race car driver, but a near fatal automobile accident days before his high school graduation convinced him he should seek his fortune in some other field. So he decided to attend community college where he developed an obsession with cinematography. He was eventually accepted into the University of Southern California's filmmaking school where he made his first film THX1138. After American Graffiti, Mr. Lucas went on to make such classNameic films as Raiders of the Lost Ark, and of course Star Wars.</dd>

        <dt className="col-sm-3">Show me the money</dt>
        <dd className="col-sm-9">After the financial success of Easy Rider (1969), with hopes of generating similarly high profit margins, American Graffiti was one of five films that Universal Studios decided to allow young filmmakers to produce. The other four "semi-independent" low-budget films were: The Hired Hand (1971); The Last Movie (1971); Taking Off (1971); and Silent Running (1972).</dd>

        <div className="col-12">
          <h3>Personal Stories</h3>
        </div>

        <dt className="col-sm-3">Henry Travers</dt>
        <dd className="col-sm-9">Henry Travers was not only the film's transportation supervisor, but the movie's car guru and driver of both cars in many scenes. "I raced that black Chevy right down Petaluma Boulevard in the first drag scene where Milner and Falfa go off at the light," tells Travers. "I also drove the deuce in the final drag scene out on Frates Road."
        <br/>
        Travers lived in Sonoma at the time and not only managed the cars in American Graffiti, but in numerous motion pictures. "Hollywood has been very good to me and I feel really fortunate to have been a part of it," commented Travers who is now retired and lives in Nevada. "Since I lived so close to Petaluma, we kept all of the cars at my home when we weren't using them for filming. We had a helluva time getting that damn '55 started. At first I thought it was a starter problem. One night, I even had a guy driving another car pull the passenger side of his car up on the sidewalk. I crawled under it and took out the starter motor and put it in the black '55."
        <br/>
        "The problem turned out to be poor grounding. You see, the car was basically a fiberglass shell, and the guy who built it had placed the battery in the trunk, and it just wasn't making a good connection. So I went out and bought the heaviest cable I could find and ran it directly to the starter," said Travers. "I remember Gary Katz, one of the film's producers, calling me into his office to ask about this as he reviewed petty cash purchases. After I explained things to him, he never questioned my purchases again."
        <br/>
        "Just for the record," Travers mentioned, "George was very gracious and very thankful. As a gesture of his appreciation, he gave me both of the '55s that were used in the film. I wish I still had them! But one thing I do have are the original THX 138 license plates that were used on the deuce. Rick Figari, the car's present owner, would love to buy them from me, but for now, they're staying put."</dd>

        <dt className="col-sm-3">Eddie McCullough</dt>
        <dd className="col-sm-9">Eddie McCullough, who lived in San Rafael in 1972, had a two-tone white and blue 1956 Ford Victoria 2-door sedan that is seen parked at the liquor store. It is also one of the cars seen outside of the Freshman Hop.
        <br/>
        According to Eddie, Ron Howard drove a VW and he loved to play ping pong, so every chance he got he went to a Kentucky Street pool hall that had a ping pong table. “We all ate together in the old Mayflower Van & Storage building and everyone was pretty friendly. I do remember that Richard Dreyfuss had a big mouth and liked to swear a lot. Finally, Mr. Lucas had to tell him to knock it off.”</dd>

        <dt className="col-sm-3">Skip White</dt>
        <dd className="col-sm-9">Skip White, who lived in San Rafael in 1972, had a 1923 bronze T-bucket roadster, which he started building in 1967. You can see it parked at Mel’s Drive-in near the end of the film where Curt is dropped off by the Pharaohs and then walks to his car.
        <br/>
        Skip answered an ad in the Marin Independent Journal. He spent three nights in San Francisco; one in San Rafael and one in Petaluma. “Extras got paid $20 per night including meals. Once, between scenes, the girl who played Budda came and sat on the front tire of my car and we talked for a while. She was very nice.”
        <br/>
        “For the scenes filmed at Mel’s, all of the cars were staged about a block away from the restaurant. A guy came up to me and asked if I would mind if they used my car for a photo shoot. I didn’t know any better so I said sure and drove it to Mel’s and parked in front of the Mel’s sign, where a photographer took pictures of Ron Howard posing with it in various configurations. The photos were to be used to promote the movie, but I never saw them anywhere. The photographer was the father of McKenzie Phillips. If anyone has a poster or any of the photos, I would love to have them!”</dd>

        <dt className="col-sm-3">Paul Haun</dt>
        <dd className="col-sm-9">Paul Haun lived in San Rafael in 1972 and had three cars that were used in the film. The black ’36 Ford Coupe in which Zuto is riding (Paul is driving, but you can’t see him); a Christmas-tree green ’56 Ford F-100 pickup truck visible in the scene where Toad picks up Debbie; and a black ’62 Ford Fairlane station wagon.
        <br/>
        At the time, Paul’s mother worked at Marin Independent Journal, which is how he found out about the need for cars. He interviewed in the parking lot of a doctor’s office in San Rafael, at the corner of First and D Streets, just off of 4th Street. In Petaluma, I drove a ’58 Chevy in several of the scenes.”
        <br/>
        He remembers that they used C-clamps to hold the fenders on to the yellow deuce couple. They also used the tassels in the chopped Mercury to hide the fact that the chop had not been entirely completed. “Everything was kind of thrown together.”</dd>

        <dt className="col-sm-3">Mike Baddeley</dt>
        <dd className="col-sm-9">Mike Baddeley lived in Petaluma in 1972. “When I was in high school in the early ‘70s (Petaluma High School ClassName of ‘73), my friends and I would often drive by various locations around town where the American Graffiti crew was filming.  The only actor or other person involved with AG known to us was Ron Howard, who we all knew as "Opie" from the Andy Griffith Show TV series, which ran in the ‘60s. So, being the clever funny guys we thought we were, we would inevitably yell out of the car window "Hey, Opie, where's Aunt Bee?", or "Hey, Opie, does Aunt Bee have any pie left for us?", or other such inane comments that always left us laughing.  To his credit, Ron Howard would always smile, give us a little wave and shake his head. Given the unexpected success of the movie, and his personal success as an actor, director and TV star, I guess he had the last laugh.”</dd>

        <dt className="col-sm-3">Jim Bergstrom</dt>
        <dd className="col-sm-9">Jim Bergstrom lived in Penngrove in 1972. He was 33 at the time and worked at Mare Island. “I was at home one evening and someone from the production staff called me up and said they were looking for old cars and trucks. I had (and still have) an American LeFrance Red ’41 Ford Pickup. It was pretty flashy with chrome and even a parachute on the tailgate. Initially they had me parked in the used car lot with the big rocking chair, but then someone from the production crew told me that my truck couldn’t be in the scene because it was too ‘noticeable’. By the way, that big rocking chair is owned by a man named Mr. Elliot, who now lives in Oregon. I believe that he still has the chair.
        <br/>
        A friend of mine, Rick Boom, was a pretty big guy and was one of the thugs in the fight scene with Toad. The name of the store on Kentucky Street where Curt is watching TV was Nielson’s Furniture at the time. Now it’s an Irish pub. One of my friend’s ’35 Plymouth was in the scene filmed outside of Petaluma High School.”</dd>

        <dt className="col-sm-3">Rick Boom</dt>
        <dd className="col-sm-9">Rick Boom, 28 at the time, lived in Petaluma and was good friends with many of officers of the Petaluma Police Department, which was in charge of security. Visiting with friend Norm Nelson and others one day, there was a guy in the group named Jim Bloom who asked me if I wanted to be an extra in the movie. I was paired up with Johnny Weissmuller, Jr. as one of the two car thief/thugs who tried to beat up Toad, only to be chased away by Milner.
        <br/>
        In the movie, I drove a ’57 show Buick owned by Al Taylor that was called the “Cubic”. During breaks and between filming, Paul Le Mat and I would sit in the back of the Impala and drink beer. I also helped position cars for various scenes and worked on the yellow Deuce to help keep it running.</dd>

        <dt className="col-sm-3">George Donate</dt>
        <dd className="col-sm-9">George Donate lived on the east side of Petaluma in 1972 and was attending Casa Grande High School. “I believe it was a Saturday night when a friend and I had gone to the Showcase Theater (now The Phoenix) to see The Exorcist. It was the last showing of the evening, so it was fairly late when we left and began walking toward home, eastbound on Washington Street.
        <br/>
        As I remember, it was just before we reached Petaluma Boulevard that a '58 Chevy with a young couple inside pulled over on our side of the street and parked just ahead of us. We walked over and said hello to Ron Howard and Cindy Williams, whom of course we didn't know at the time. They were laughing because the light and microphone under the dash on Cindy's side were making her uncomfortable because they were interfering with her dress.
        <br/>
        I'm not sure if our presence interrupted filming, but suddenly a nice gentleman with a funny beard came up from behind us and was smiling, too. He was very friendly and took the time to talk with us for a couple of minutes and explain about the movie he was making, after which he thanked us for our compliments and said goodbye. Of course, it was George Lucas.
        <br/>
        These few memorable minutes have made a favorable and lasting impression on me for all these years. Thanks for the opportunity to share my story.”</dd>

        <dt className="col-sm-3">Other individuals</dt>
        <dd className="col-sm-9">Other individuals whose cars were in the film include Rocky Moreno, owner of the Studebaker in which the girls from out of town were riding; Bill Vanderplug; Dennis Sabraw; John Moses (’48 Ford pickup) and Colin Vokin (’41 blue coupe)</dd>
      </dl>
      <hr/>
      <p>
        If you were an extra in the film or one or more of your cars were used, we would like to interview you. Please contact us at <a href="mailto:movie@americangraffiti.net?subject=Personal stories from filming">movie@americangraffiti.net</a>
      </p>
      </div>
    </React.Fragment>
  );
}

export default Scenes;