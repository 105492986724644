import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";
import WolfmanStatement from "../../components/WolfmanStatement";

function Trivia() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Trivia and Technicalities" tagline="From the Film" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
      <h1>Trivia and Technicalities</h1>
      <p>
        Filmed in just 28 consecutive nights, American Graffiti has its share of mistakes, continuity issues, and anachronisms. However, what makes the film especially unique and very genuine is that Mr. Lucas kept some of the miscues and even encouraged actors to “live” the movie, so to speak, frequently allowing them to ad-lib their lines and even choose where to position themselves in a scene.
        <br/>
        <br/>
        One of the best examples of this takes place after the drag race when Milner admits to Toad that Falfa was winning before he lost control and crashed. It was totally improvised by Le Mat and Smith because they hadn’t studied their lines for that scene, which was scheduled for filming at another time.
      </p>
      <dl className="row">
        <dt className="col-sm-3">Toad’s Scooter Crash</dt>
        <dd className="col-sm-9">Unfamiliar with the controls of the scooter on which he is riding, when Toad first appears in the film, he crashes his Vespa into a trash can. This mishap is unplanned but he continues the action as if it had been scripted. In the short time it takes Curt to walk from his 1972 Citroen 2CV to join Steve and Toad, the top is back on the can and the scooter is upright in its normal parking position.</dd>

        <dt className="col-sm-3">Mel’s phone</dt>
        <dd className="col-sm-9">The pay telephone at Mel's Drive-in is a model that was not introduced until the late 1960s.</dd>

        <dt className="col-sm-3">Where were you in ’62?</dt>
        <dd className="col-sm-9">
          <ol>
            <li>At the beginning of the movie, a red Mustang coupe is seen in the distant background parked across the street from Mel's Drive-In. Mustangs weren’t introduced until 1964.</li>
            <li>Several scenes after Milner picks up Carol and makes a right turn off the main drag, a white 1971 Toyota Corolla is seen waiting at the intersection.</li>
            <li>In the scene where Toad is first trying out his new wheels, he drives past a 1967 Chevy Impala that is parked along the curb.</li>
          </ol>
        </dd>

        <dt className="col-sm-3">The yellow Chevy</dt>
        <dd className="col-sm-9"> During the first cruising scenes, a yellow '56 Chevy is parked on the street, hood raised with a couple of boys working on the engine. In the very next scene, the Chevy’s hood is closed. A few scenes later, you see the same yellow Chevy again, but this time it is being driven. Finally, Milner drives past it one more time where it is seen still parked on the side of the street with the hood down.</dd>

        <dt className="col-sm-3">Right place, wrong time</dt>
        <dd className="col-sm-9">Also in the first cruising scene, Milner pulls up next to the black ’36 Ford coupe and chats with Zuto. After the conversation, he leans back in his seat slightly and for a split second you can see Carol sitting in the passenger seat facing him. It wasn’t until later in the film that she became his passenger.</dd>

        <dt className="col-sm-3">Carol gets into Milner’s coupe</dt>
        <dd className="col-sm-9">Just as Milner is ready to stop his car to let Carol in for the first time, he passes a dark color 4-door sedan parked at the curb. As Carol runs around to get into his car, the dark sedan has been replaced by a blue 1955 Plymouth. You will also notice that without trying the outside door handle first, Carol reaches through the window of Milner’s car to use the inside handle to open the door.</dd>

        <dt className="col-sm-3">At the hop</dt>
        <dd className="col-sm-9">The guitar played by the lead guitarist of Herbie and The Heartbeats at the Freshman Hop is a post-1966 Fender Stratocaster (with the CBS headstock).</dd>

        <dt className="col-sm-3">Picking up Debbie</dt>
        <dd className="col-sm-9">When Toad turns the ‘58 Impala around to pick up Debbie, she walks past the same blue Corvair three times. When she steps into the street to talk to Toad, the Corvair is parked next to the curb. When the scene switches to a wide angle, the Corvair has been replaced by a gold Buick. Then, as they drive away, the Corvair reappears in its original location.</dd>

        <dt className="col-sm-3">Stopped by the cop</dt>
        <dd className="col-sm-9">When Milner is stopped by Officer Holstein, the location is near a movie theater (filmed in San Rafael, CA). The theater has a poster for the 1972 movie Cabaret. Also, the Wolfman Jack montage on the radio includes part of the 1969 song “Grazing In the Grass” by Friends of Distinction.</dd>
        
        <dt className="col-sm-3">The water balloon</dt>
        <dd className="col-sm-9">When Milner and Carol are cruising, a car full of girls pulls up next to them and one of the girls throws a water balloon through the window and it hits Carol in the face. It was scripted for the balloon to hit a location inside the car and splash on Carol. Both Le Mat and Phillips immediately began to laugh and Phillips ad-libbed the scene so perfectly that director Lucas kept it.</dd>

        <dt className="col-sm-3">Carol’s revenge</dt>
        <dd className="col-sm-9">After Carol is hit with the water balloon, she tells Milner to catch them at the traffic light where he and Carol take revenge: 
          <ol>
            <li>As they jump out of Milner’s coupe and John proceeds to let the air out the tires on the girl’s car, Carol sprays its windshield with shaving cream. After she finishes and goes to spray the back window, the front windshield is seen without the shaving cream.</li>
            <li>The girls in the car roll up the passenger window twice during this scene: Once as Milner and Carol are getting out of his car, and again as they approach the girl’s car.</li>
            <li>As the girl in the backseat rolls up her window, a bearded man with a mustache can be seen in the reflection of the window. Motioning upward with his left arm and wearing a white long-sleeved shirt with what appears to be a maroon sweater-vest, this appears to be director Lucas.</li>
            <li>As they jump from Milner’s deuce, they leave the doors open. After finishing their revenge, they run back to the deuce, which now has the doors closed. 5) Paul Le Mat actually jumped onto and over the car during each take. Director Lucas became concerned that Le Mat's boots would put dents in the hood and trunk and cautioned him to be careful. Le Mat thought the car was a movie prop and didn’t realize that it belonged to someone.</li>
          </ol>
        </dd>
        
        <dt className="col-sm-3">The elusive T-Bird</dt>
        <dd className="col-sm-9">After Curt is kicked out of Bobbie’s car and runs up the street chasing the white T-Bird, a 1973 Olds Cutlass is seen behind him as he stands alone in the intersection.</dd>

        <dt className="col-sm-3">The liquor store</dt>
        <dd className="col-sm-9">When Toad tries to get someone to buy “brew” for him, the sign on the door of the liquor store, although appropriate to the period ("Winston" ad), says "Push" on the "Pull" side of the door.</dd>
        
        <dt className="col-sm-3">Did you get it?</dt>
        <dd className="col-sm-9">The scene at the liquor store in which Toad asks Debbie for money was shot in one take. Candy Clark wanted to do it again because she flubbed her "Did you get it?" line, but Lucas said that was it, they were printing the first take. Also, the sign on the door of the liquor store, although appropriate to the period (Winston cigarette ad), says "Push" on the "Pull" side of the door.</dd>

        <dt className="col-sm-3">The auto wrecking yard</dt>
        <dd className="col-sm-9">When Carol and John are walking through the wrecking yard, many 1960-1963 cars are seen in the background including a 1963 Studebaker Lark, a 1961 Pontiac, and a 1960 Ford.</dd>
        
        <dt className="col-sm-3">Watching TV</dt>
        <dd className="col-sm-9">Sitting atop the hood of a car, Curt watches television through the window of an appliance store. All of the sets are early 1970s models.</dd>

        <dt className="col-sm-3">The arcade</dt>
        <dd className="col-sm-9">The pinball machines in the arcade that the Pharaohs loot are all newer than the 1962 setting of the film: Buckaroo-1965; Base Hit-1967; Royal Guard-1968; Wild Wild West-1969; SkyRocket-1970; Vampire-1971.</dd>

        <dt className="col-sm-3">The mail box</dt>
        <dd className="col-sm-9">Seeing Toad being pounded on by the two thugs, Milner makes a U-turn and runs to his rescue. As he makes the turn, the mail box seen in the background bears the current white eagle logo, which was not put into use until well after 1962.</dd>

        <dt className="col-sm-3">Inside the XERB radio studio</dt>
        <dd className="col-sm-9">The scene begins with a wide angle shot of the DJ putting the left turntable arm back on its support. In the next scene, close-up, when the DJ and Curt are talking, the arm is back on the record. The DJ lifts and puts it back on its support again! At the end of the dialogue, the arm is back on the record. Also, as the two are talking, the position of the top, right-hand tape in the rack behind the DJ keeps switching places between the two uppermost slots.</dd>

        <dt className="col-sm-3">Open headers?</dt>
        <dd className="col-sm-9">After Milner open his headers at the gas station in preparation for the race with Falfa, he eventually drives to Paradise Road for the race where, because of the cold temperature of the morning, the exhaust is visible coming from his tailpipes at the back of the car.</dd>

        <dt className="col-sm-3">The drag race and crash</dt>
        <dd className="col-sm-9">
          <ol>
            <li>After the crash, Falfa walks away from his overturned car with his hat in hand, yet in the next scene Milner is seen holding the hat before handing it back to Falfa.</li>
            <li>The black 2-door ‘55 Chevy driven by Falfa in the cruising scenes has wide chrome wheels and tires; however in the drag race and after the crash when the Chevy is upside down, the wheels are standard steel rims spray-painted silver.</li>
            <li>Immediately after Falfa's Chevy flips over, no fuel tank is visible and the trunk lid is open; however, in the scene, just before the explosion, the trunk is closed.</li>
          </ol>
        </dd>

        <dt className="col-sm-3">It’s time to go</dt>
        <dd className="col-sm-9">In the final scene as Curt walks toward the airplane to board, the pilot starts the number one (left outboard) engine. In real life, a pilot would never start an engine prior to all passengers being aboard and all doors closed.</dd>
      </dl>
      
      <h2>Other Continuity Goofs</h2>
      <dl className="row">
        <dt className="col-sm-3">The missing license plate</dt>
        <dd className="col-sm-9">In several scenes, the ’32 deuce coupe is missing the front license plate.</dd>

        <dt className="col-sm-3">The magic cigarette pack</dt>
        <dd className="col-sm-9">In close ups of Milner driving, the package of Camel cigarettes rolled up in his T-shirt sleeve changes from front to back.
        </dd>

        <dt className="col-sm-3">Toad’s shoes</dt>
        <dd className="col-sm-9">Toad wore white shoes throughout the movie; except for the black and white saddle shoes he is seen wearing in the scene where he lets Debbie into his car for the first time.</dd>

        <dt className="col-sm-3">Same license plates</dt>
        <dd className="col-sm-9">The Studebaker in which Carol, Judy and other girls are riding has the license plate JPM 351, which is not only the same plate on Steve ‘58 Impala, but is also seen on the car on which Curt is sitting when he first meets the Pharaohs. The Edsel driven by Laurie Henderson has the plates PRC 596, which are also seen on a silver ‘57 Chevy and a red pickup.</dd>

        <dt className="col-sm-3">The XERB truth</dt>
        <dd className="col-sm-9">XERB radio, the station from which Wolfman Jack broadcasted in the movie was in reality XERB 1090AM radio, a 50,000 watt “border-blaster” located south of the border in Tijuana/Rosarito, Mexico, near San Diego, California, and is from where The Wolfman did broadcast beginning in 1965, prior to a stint at XERF radio, another Mexican station located south of Del Rio, Texas.
        <br/>
        Prohibited by a law known as the Brinkley Act, it is a myth that U.S. licensed radio stations transmitted their signals to repeaters in Mexico for rebroadcast back across the border at “power-pumping” signal strengths. Known as "Outlaw Mexican Radio", Carlos, one of the Pharaoh’s, refers to this fabled practice when he exclaims "He flies that thing all the way around the world! It's against the law, man!"</dd>
      </dl>
      

    </div>
    <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Trivia;