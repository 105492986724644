import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Tbird() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The '56 T-Bird" tagline="Cars of American Graffiti" backgroundImage="../../images/film/tbirdBanner.png"/>
      <div className="container">
        <h1>The '56 T-Bird</h1>
        <p>
          Clay and May Daily purchased their '56 Thunderbird convertible in 1964 from a San Bernardino, California Ford dealer’s used car lot. It was red and had 55,000 miles on the odometer. Several years later they moved to Petaluma and had it painted white. Ironically, they lived on Joan Drive, only about four houses away from Cruisin’ The Boulevard Board Member John Furrer who at the time, of course, wasn’t acquainted with them.
          <br/>
          <br/>
          In 1972, May was downtown Petaluma doing some shopping and when she returned to the car there was a piece of a brown paper bag under one of the wiper blades. Written on it was an invitation to have the car be included in the filming of a movie. They thought it was just someone playing a prank, but called the listed phone anyway. Of course, it turned out to be Lucas Films and the rest is history. Since most of the filming was done at night, they were able to watch the action.
          <br/>
          <br/>
          In 1999, the Dailys and the T-Bird were invited to appear on the the Leeza television show, hosted by Leeza Gibbons. It was a surprise reunion for Suzanne Somers who was appearing as a guest celebrity. At the appropriate time, Leeza had Clay and May, who were seated in the audience, stand up and then asked Suzanne if she recognized them. Of course, after almost 30 years she didn’t, yet when Leeza told Suzanne that they knew her she was befuddled. For the surprise, everyone went outside into the studio parking lot where Suzanne's husband drove up in the T-Bird!
          <br/>
          <br/>
          Update:  For many years Clay and May have been bringing their T-Bird to Petaluma each May to help celebrate Petaluma’s Salute to American Graffiti.  When they finally said it was time to say goodbye to their baby, they entrusted their baby to a good friend who lives in Petaluma.  They told us that it was their desire to have the car come back to Petaluma.
        </p>
      </div>
    </React.Fragment>
  );
}

export default Tbird;