import React from "react";
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom';
import MailToLink from "./MailToLink";



function Footer() {
  return (
    <footer>
      <Nav className="justify-content-center" activeKey="/home">
        <Nav.Item>
          <Nav.Link as={Link} to="/show">Car Show</Nav.Link>
          <Nav.Link as={Link} to="/art">Event Art</Nav.Link>
          <Nav.Link as={Link} to="/movie">The Film</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/projects/scholarships">Scholarships</Nav.Link>
          <Nav.Link as={Link} to="/projects/life">Save-a-Life</Nav.Link>
          <Nav.Link as={Link} to="/projects/memorial">In Memory of</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/about">About Us</Nav.Link>
          <Nav.Link as={Link} to="/projects/volunteer">Volunteer</Nav.Link>
          <MailToLink label="Email Us" mailto="mailto:info@americangraffiti.net" />
        </Nav.Item>
      </Nav>
      <p className="center">
      © Cruisin' the Boulevard, Inc., 2023
      <br/></p>
    </footer>
  );
}

export default Footer;