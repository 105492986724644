import styled from "styled-components";

const NotFound = () => {
  return <div className ='container'>
    <NotFoundImg className="card-img-top" src="/images/404.png" alt="Page not found"/>
    </div>;
};

const NotFoundImg = styled.img`
  width: 100%;
  margin-top: 3%;
`

export default NotFound;