import React from "react";
import PageBanner from "../../components/PageBanner";
import MemorialCard from "../../components/MemorialCard";

import useScrollUp from "../../utilities/useScrollUp";

function Memorial() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="American Graffiti" tagline="In Memoriam" backgroundImage="../images/memorials/memorialBanner.png"/>
      <div className="container"> 
        <h1>In Memoriam</h1>
        <p>
          Robert Frost once wrote that Nothing Gold Can Stay and so it is that we pay tribute to members of the cast and production staff who have made the final turn onto the ultimate ‘main drag’; cruising somewhere over the rainbow. You will always be gold in the hearts of American Graffiti fans everywhere.
        </p>
        <div className="card-deck">
          <MemorialCard image="../images/memorials/travers.png" altText="Henry Richard Travers" name="Henry Richard Travers" date="August 8, 1937 to August 20, 2006" link="memorials/travers"/>
          <MemorialCard image="../images/memorials/padilla.jpeg" altText="Manuel Padilla, Jr." name="Manuel Padilla, Jr." date="July 13, 1955 to January 29, 2008" link="memorials/padilla"/>
          <MemorialCard image="../images/memorials/poremba.png" altText="Rich Poremba" name="Rich Poremba" date="March 26, 1944 to September 19, 2010" link="memorials/poremba"/>
          <MemorialCard image="../images/memorials/maley.png" altText="William Marley" name="William 'Bill' Marley" date="March 5, 1930 to February 14, 2013" link="memorials/maley"/>
        </div>

        <div className="card-deck">
          <MemorialCard image="../images/memorials/donPhelps.jpg" altText="Don Phelps" name="Don Phelps" date="October 22, 1940 to October 2017" link="memorials/phelps"/>
          <MemorialCard image="../images/memorials/suememorial.jpg" altText="Susan Giammattei" name="Susan Giammattei" date="August 24, 1957 to July 28, 2016" link="memorials/giammattei"/>
          <MemorialCard image="../images/memorials/wahl.png" altText="Bob Whal" name="Robert 'Bob' Wahl" date="April 26, 1944 to June 25, 2021" link="memorials/wahl"/>
          <MemorialCard link="#"/>                  
        </div>
      </div>
    </React.Fragment>
  );
}

export default Memorial;