import React, {Suspense} from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Navigation, Footer} from "./pages";
import LoadingSpinner from "./ui/LoadingSpinner";
import usePageTracking from "./utilities/usePageTracking";
import ReactGA from "react-ga4";

const Scholarships = React.lazy(() => import('./pages/projects/Scholarships'));
const Life = React.lazy(() => import('./pages/projects/Life'));
const Volunteer = React.lazy(() => import('./pages/projects/Volunteer'));
const Memorial = React.lazy(() => import('./pages/projects/Memorial'));
const Giammattei = React.lazy(() => import('./pages/projects/memorials/Giammattei'));
const Maley = React.lazy(() => import('./pages/projects/memorials/Maley'));
const Padilla = React.lazy(() => import('./pages/projects/memorials/Padilla'));
const Phelps = React.lazy(() => import('./pages/projects/memorials/Phelps'));
const Poremba = React.lazy(() => import('./pages/projects/memorials/Poremba'));
const Travers = React.lazy(() => import('./pages/projects/memorials/Travers'));
const Wahl = React.lazy(() => import('./pages/projects/memorials/Wahl'));
const Chevy = React.lazy(() => import('./pages/movie/cars/Chevy'));
const Citroen = React.lazy(() => import('./pages/movie/cars/Citroen'));
const CopCar = React.lazy(() => import('./pages/movie/cars/CopCar'));
const DuceCoupe = React.lazy(() => import('./pages/movie/cars/DuceCoupe'));
const Impala = React.lazy(() => import('./pages/movie/cars/Impala'));
const Mercury = React.lazy(() => import('./pages/movie/cars/Mercury'));
const Tbird = React.lazy(() => import('./pages/movie/cars/Tbird'));
const Cars = React.lazy(() => import('./pages/movie/Cars'));
const Essentials = React.lazy(() => import('./pages/movie/Essentials'));
const Filming = React.lazy(() => import('./pages/movie/Filming'));
const Music = React.lazy(() => import('./pages/movie/Music'));
const Scenes = React.lazy(() => import('./pages/movie/Scenes'));
const Thennow = React.lazy(() => import('./pages/movie/Thennow'));
const Trivia = React.lazy(() => import('./pages/movie/Trivia'));
const Wolfman = React.lazy(() => import('./pages/movie/Wolfman'));
const Dinner = React.lazy(() => import('./pages/show/Dinner'));
const Info = React.lazy(() => import('./pages/show/Info'));
const Social = React.lazy(() => import('./pages/show/Social'));
const Sponsors = React.lazy(() => import('./pages/show/Sponsors'));
const Show = React.lazy(() => import('./pages/Show'));
const About = React.lazy(() => import('./pages/About'));
const Art = React.lazy(() => import('./pages/Art'));
const Movie = React.lazy(() => import('./pages/Movie'));
const Home = React.lazy(() => import('./pages/Home'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

ReactGA.initialize('G-HHTB0E2WHS');

function App() {
  usePageTracking();
  return (
    <Router>
      <div className="App">
          <Navigation />
          <div className="content">
            <Suspense fallback={<LoadingSpinner/>}><Switch>
                <Route path="/projects/scholarships" component={Scholarships}/>
                <Route path="/projects/life" component={Life}/>
                <Route path="/projects/Volunteer" component={Volunteer}/>
                <Route path="/projects/memorials/giammattei" component={Giammattei}/>
                <Route path="/projects/memorials/maley" component={Maley}/>
                <Route path="/projects/memorials/padilla" component={Padilla}/>
                <Route path="/projects/memorials/phelps" component={Phelps}/>
                <Route path="/projects/memorials/poremba" component={Poremba}/>
                <Route path="/projects/memorials/travers" component={Travers}/>
                <Route path="/projects/memorials/wahl" component={Wahl}/>
                <Route path="/projects/memorial" component={Memorial}/>
                <Route path="/movie/cars/chevy" component={Chevy}/>
                <Route path="/movie/cars/citroen" component={Citroen}/>
                <Route path="/movie/cars/copcar" component={CopCar}/>
                <Route path="/movie/cars/ducecoupe" component={DuceCoupe}/>
                <Route path="/movie/cars/impala" component={Impala}/>
                <Route path="/movie/cars/mercury" component={Mercury}/>
                <Route path="/movie/cars/tbird" component={Tbird}/>
                <Route path="/movie/cars" component={Cars}/>
                <Route path="/movie/essentials" component={Essentials}/>
                <Route path="/movie/filming" component={Filming}/>
                <Route path="/movie/music" component={Music}/>
                <Route path="/movie/scenes" component={Scenes}/>
                <Route path="/movie/thennow" component={Thennow}/>
                <Route path="/movie/trivia" component={Trivia}/>
                <Route path="/movie/wolfman" component={Wolfman}/>
                <Route path="/movie" component={Movie}/>
                <Route path="/show/dinner" component={Dinner}/>
                <Route path="/show/info" component={Info}/>
                <Route path="/show/social" component={Social}/>
                <Route path="/show/sponsors" component={Sponsors}/>
                <Route path="/show" component={Show}/>
                <Route path="/about" component={About}/>
                <Route path="/art" component={Art}/>
                <Route path="/" exact component={Home} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Suspense>
          </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;