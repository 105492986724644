import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";
import WolfmanStatement from "../../components/WolfmanStatement";

function Essentials() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="The Essentials" tagline="film facts" backgroundImage="../../images/film/behindBanner.png"/>
      <div className="container">
        <h1>The Essentials</h1>
        <p>
          First Preview: January 28, 1973, Northpoint Theater, San Francisco, CA
          <br/>
          Second Preview: May 15, 1973, Writer’s Guild Theater, Beverly Hills, CA
          <br/>
          Released by Universal Studios: August 1, 1973
          <br/>
          Runtime: Original release: 110min ~ Re-release (1978) 112min
          <br/>
          <br/>
          A reflection of George Lucas’ years as a teenager growing up in Modesto, California in the early ‘60s, American Graffiti personifies the time in a young man’s life when high school is over and he must decide about his future. Filmed almost entirely at night, this coming-of-age story set in September, 1962, evolves around four longtime friends, two of whom intend to leave for college the following day. Especially significant is the film’s portrayal of teenagers who cruise the main drag in their cars and hot rods, a ‘50s and early ‘60s ritual that took place not only in Modesto, but also in Petaluma and in “Everytown, U.S.A.”.
          <br/>
          <br/>
          Just as in American Graffiti, many of Mr. Lucas’ films are based on the transitional time in a person’s life when a decision must be made to make a major change; and the bravery behind that decision to take the “first step” into the unknown.
          <br/>
          <br/>
          In its original release, Universal Studios cut the following three scenes from the film that after the success of Star Wars in 1978, Mr. Lucas added to the re-release:
        </p>
        <ul>
          <li>Used car salesman sitting in the big rocking chair</li>
          <li>Steve tells off Mr. Krook (Marblehead) at the Freshman Hop</li>
          <li>Falfa sings Some Enchanted Evening to Laurie</li>
        </ul>
        <h3>Production Staff</h3>
        <div className="row">
          <div className="col-6">
            <ul>
              <li>Director and writer --- George Lucas</li>
              <li>Screenwriters --- Gloria Katz & Willard Huyck</li>
              <li>Producers --- Francis Ford Coppola & Gary Kurtz</li>
              <li>Visual Consultant --- Haskell Wexler</li>
              <li>Directors of Photography  --- Jan D'Alquen & Ron Eveslage</li>
              <li>Sound Montage & Re-recording --- Walter Murch</li>
              <li>Trans. Supervisor/Car --- GuruHenry Travers</li>
            </ul>
          </div>
          <div className="col-6">
            <ul>
              <li>Editors -- Verna Fields & Marcia Lucas</li>
              <li>Production Designer --- Dennis Lynton Clark</li>
              <li>Set Designer --- Douglas Freeman</li>
              <li>Costume Designer --- Aggie Guerard Rodgers</li>
              <li>Casting --- Fred Roots & Mike Fenton</li>
              <li>First Assistant Director --- Ned Kopp</li>
              <li>Lighting Gaffer --- Bill Maley</li>
            </ul>
          </div>
        </div>
        <h3>Cast</h3>
        <div className="row">
          <div className="col-6">
             <ul>
              <li>Curt Henderson --- Richard Dreyfuss</li>
              <li>Steve Bolander --- Ron Howard</li>
              <li>John Milner --- Paul Le Mat</li>
              <li>Terry 'The Toad' Fields --- Charles Martin Smith</li>
              <li>Laurie Henderson --- Cindy Williams</li>
              <li>Debbie Dunham --- Candy Clark</li>
              <li>Carol --- Mackenzie Phillips</li>
              <li>XERB Disc Jockey --- Wolfman Jack</li>
              <li>Joe Young --- Bo Hopkins</li>
              <li>Carlos --- Manuel Padilla, Jr.</li>
              <li>Ants --- Beau Gentry</li>
              <li>Bob Falfa --- Harrison Ford</li>
              <li>Officer Holstein --- Jim Bohan</li>
              <li>Budda Macrae --- Jana Bellan</li>
              <li>Wendy --- Deby Celiz</li>
              <li>Bobbie Tucker --- Lynne Marie Stewart</li>
              <li>Mr. Bill Wolfe --- Terence McGovern</li>
              <li>Peggy --- Kathleen Quinlan</li>
              <li>Eddie --- Tim Crowley</li>
              <li>Mr. Gordon --- Scott Beach</li>
              <li>Car Salesman --- John Brent</li>
              <li>Bozo --- Gordon Analla</li>
              <li>Station Attendant --- John Bracci</li>
              <li>Girl in Studebaker --- Jody Carlson</li>
              <li>Guy at Bar --- Del Close</li>
              <li>Man at Accident --- Chuck Dorsett</li>
              <li>Kid at Accident --- Stephen Knox</li>
            </ul>

          </div>
          <div className="col-6">
            <ul>
              <li>Man at Liquor Store --- Joe Miksak</li>
              <li>Bum at Liquor Store --- George Meyer</li>
              <li>Liquor Store Thief --- James Cranna</li>
              <li>Badass #1 --- Johnny Weissmuller Jr.</li>
              <li>Badass #2 --- Rick Boom</li>
              <li>Clerk at Liquor Store --- William Niven</li>
              <li>Hank Anderson --- Al Nalbandian</li>
              <li>Dale --- Bob Pasaak</li>
              <li>Al --- Chris Pray</li>
              <li>Judy --- Susan Richardson</li>
              <li>Ferber --- Fred Ross</li>
              <li>Old Woman --- Jan Dunn</li>
              <li>Old Man --- Charlie Murphy</li>
              <li>Kip Pullman --- Ed Greenberg</li>
              <li>Girl in Dodge --- Lisa Herman</li>
              <li>Mr. Kroot --- Irving Israel</li>
              <li>Jane, Girl at Dance --- Kay Ann Kemper</li>
              <li>Announcer at Dance --- Caprice Schmidt</li>
              <li>Vic --- Joe Spano</li>
              <li>Bob Falfa's Girl --- Debralee Scott</li>
              <li>Jeff --- Ron Vincent</li>
              <li>Carhop --- Donna Wehr</li>
              <li>Balloon Girl --- Cam Whitman</li>
              <li>Girl at Dance --- Jan Wilson</li>
              <li>Blonde in T-Bird --- Suzanne Somers</li>
              <li>Girl --- Linda Christensen</li>
              <li>Herby & The Heartbeats --- Flash Cadillac & The Continental Kids: Warren Knight, Sam McFadin,Kris Moe, Linn Phillips III, George Robinson</li>
            </ul>
          </div>
        </div>
        <h3>Awards and Honors</h3>
        <div className="row">
          <div className="col-3 offset-2">
            <div className="card">
              <a href="https://www.afi.com/afis-100-years-100-movies/" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="../images/film/afiLogo.png" alt="American Film Institute"/>
                <div className="card-body">
                  <p className="card-text">Best 100 films --- American Film Institute</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <a href="https://www.goldenglobes.com/film/american-graffiti" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="../images/film/ggLogo.jpeg" alt="Golden Globe Award"/>
                <div className="card-body">
                  <p className="card-text">
                    Best Picture --- Golden Globe Award
                  </p>
                  <hr/>
                  <p className="card-text">
                    Most Promising Newcomer --- Paul Le Mat, Golden Globe Award
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <a href="https://mubi.com/films/american-graffiti" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="../images/film/lffLogo.png" alt="Locarno International Film Festival"/>
                <div className="card-body">
                  <p className="card-text">Bronze Leopard --- Locarno International Film Festival</p>
                </div>
              </a>
            </div>
          </div>  
        </div>
      </div>
      <WolfmanStatement/>
    </React.Fragment>
  );
}

export default Essentials;