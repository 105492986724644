import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";

function Scholarships() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Scholarships" tagline="Supporting our community's youth" backgroundImage="../images/scholarships/scholarshipBanner.png"/>
      <div className="container">
        <h1>Scholarships</h1>
        <p>
          Scholarships are awarded to Petaluma area high schools’ graduating seniors and students continuing in these studies who are pursuing a career in automotive technology/repair/mechanics/engineering, performing arts, videography, film and media industry, computer sciences, and health & safety. The maximum number a person can be awarded this scholarship is two (2) times.
        </p>
        <div className="row">
          <div className="col-6 offset-3 center">
            <br/>
            <h3>2024 awards closed; 2025 details to come</h3>
            {/*<button className="btn btn-primary">Apply</button>*/}
            <br/>
          </div>
        </div>
        <br/>
        <h2>General Description of fields of study are:</h2>
        <p>
          <strong>Automotive:</strong>  Includes anything related to the automotive field, from auto magazine writing to actual automotive repair/mechanics/technology and automotive design and engineering.
          <br/>
          <br/>
          <strong>Performing Arts, Movie, TV or Radio, Video Industries:</strong> This includes anything related to the movie industry, acting, producing, sound production, filming, radio, videography, music, etc.
          <br/>
          <br/>
          <strong>Computer Sciences:</strong> Includes anything related to the computer science industry, computer support, network technician, web development, computer software engineer, graphic artist, information systems administrator, 3D animation, etc.
          <br/>
          <br/>
          <strong>Health and Safety:</strong> We are proud to offer this scholarship to those who wish to pursue careers in Law Enforcement, Firefighting, First Aide, First Responders and any medical related field of study.
          <br/>
          <br/>
          <strong>Trade Schools and Vocational Programs:</strong>  This includes anyone going to school to work with their hands like an Electrician, Plumber ,Carpenter, Mason, Roofer Ect.
        </p>
      </div>
    </React.Fragment>
  );
}

export default Scholarships;