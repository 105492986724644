import React from "react";

const useScrollUp =() =>{
  React.useEffect(() => {       
    window.scrollTo(0, 0);     
  }, 
  []);
}

export default useScrollUp;
