import React from "react";
import PageBanner from "../../../components/PageBanner";

import useScrollUp from "../../../utilities/useScrollUp";

function Wahl() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="In Memory of" tagline="Robert 'Bob' Wahl" backgroundImage="../../images/memorials/memorialBanner.png"/>
      <div className="container">
        <h1>Bob Wahl</h1>
        <h3>April 26, 1944 to June 25, 2021</h3>    
        <p>
         We are sad to announce Bob&#39;s passing. Robert (Bob) Wahl, 77, born in Phoenix, predeceased by parents
         Karl and Mary Wahl; siblings - Joan Evans, living in Rocklin, California and Karl in Canmore, Alberta Canada.
         Children are Brian and Susan. Bob, an inveterate tire salesman; in later years was a very active Sebastopol Rotarian and the final ten years with Petaluma Sunrise Rotary - enthusiastically donated countless hours to local helping - others projects,
         Salvation Army, and Rotary food drives, etc. Petaluma&#39;s American Graffiti car organization saw his red
         and rare 1965 Ford Galaxy a part of that annual event. Bob loved Petaluma and Sonoma County, baking lots of
         Christmas cookies for friends and caregivers. A consummate teller of jokes and ribald stories, he will be
         missed.
        </p>
      </div>
    </React.Fragment>
  );
}

export default Wahl;